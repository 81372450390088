import { CargoProject } from '@/models/CargoProject'
import { CargoType } from './CargoType'
import { Company } from '@/models/Company'
import { EmptyVessel } from '@/models/EmptyVessel'
import { Facility } from '@/models/Facility'
import { FavouriteGroup } from '@/models/FavouriteGroup'
import FinancialConnection from '@/models/FinancialConnection'
import { FlatTransport } from '@/models/FlatTransport'
import { Fleet } from '@/models/Fleet'
import { FleetMember } from '@/models/FleetMember'
import { Membership } from '@/models/Membership'
import { Message } from '@/models/Message'
import { Model } from '@/models/Model'
import { Offer } from '@/models/Offer'
import { PluralResponse } from 'coloquent/dist/response/PluralResponse'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Vessel } from '@/models/Vessel'
import { VesselLog } from '@/models/VesselLog'
import { BillOfLading } from '@/models/BillOfLading'
import moment from 'moment'

export default class User extends Model {
	protected jsonApiType = 'users'

	protected readOnlyAttributes = ['spoofed']

	public static async sync(force: boolean = false): Promise<void> {
		const store = (await import('@/store/index')).store
		// @ts-ignore
		if (!store.state.auth.user.id || force) {
			const user = await this.fetchMe()
			if (!user.spoofed && !user.isTestAccount) {
				// activate fullstory only for normal accounts which are logged in
				;(window as any)._activate_fullstory = true
			}

			store.commit('auth/setInitialized', true)

			store.commit('auth/setUser', {
				id: user.getApiId(),
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				companyName: user.getCompany() ? user.getCompany()!.name : '',
				role: user.role,
				active: user.active,
				spoofed: user.spoofed,
				isSuperUser: user.isSuperUser,
				localeFirstDayOfWeek: moment.localeData().firstDayOfWeek()
			})

			const vessels = user.getVessels()

			if (vessels.length) {
				store.commit(
					'auth/setVessels',
					vessels.map((vessel) => {
						return vessel.getApiId()
					})
				)
				store.commit('vessels/cache', vessels)
			}

			store.commit('auth/setCompanies', [user.getCompany()])
			store.commit('auth/setEmptyVessel', user.getActiveEmptyVessel())
			store.commit('auth/setQueuedEmptyVessel', user.getQueuedEmptyVesselReport())

			store.commit('auth/setFinancialConnection', user.getFinancialConnection())
		}

		const theUser = await store.dispatch('messages/syncBadges')
		store.commit('auth/setUserMemberships', theUser.getMemberships())
	}

	public static async fetchMe(): Promise<User> {
		const store = (await import('@/store/index')).store
		await store.dispatch('users/findMe')
		return store.getters['users/peekRecord']('me')
	}

	public static async fetchMeLazy(): Promise<User> {
		const store = (await import('@/store/index')).store
		if (!store.getters['users/hasCached']('me')) {
			await store.dispatch('users/findMe')
		}

		return store.getters['users/peekRecord']('me')
	}

	public static async fetchColleaguesLazy(): Promise<Array<User>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['users/hasRelationCached']('me', 'colleagues')) {
			const usersQuery = (await User.fetchMeLazy()).getCompany()!.users().get()
			const users = ((await usersQuery) as PluralResponse).getData() as Array<User>
			store.commit('users/cacheRelationship', {
				id: 'me',
				relationName: 'colleagues',
				values: users
			})
		}

		return store.getters['users/peekRelation']('me', 'colleagues')
	}

	public static async fetchFrequentlyUsedStartTerminalsLazy(): Promise<Array<Facility>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['users/hasRelationCached']('me', 'frequentlyUsedStartTerminals')) {
			const frequentlyUsedTerminals = (
				(await (await User.getCurrentUser()).frequentStartTerminals().get()) as PluralResponse
			).getData() as Array<Facility>

			store.commit('users/cacheRelationship', {
				id: 'me',
				relationName: 'frequentlyUsedStartTerminals',
				values: frequentlyUsedTerminals
			})
		}

		return store.getters['users/peekRelation']('me', 'frequentlyUsedStartTerminals')
	}

	public static async fetchFrequentlyUsedEndTerminalsLazy(): Promise<Array<Facility>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['users/hasRelationCached']('me', 'frequentlyUsedEndTerminals')) {
			const frequentlyUsedTerminals = (
				(await (await User.getCurrentUser()).frequentEndTerminals().get()) as PluralResponse
			).getData() as Array<Facility>

			store.commit('users/cacheRelationship', {
				id: 'me',
				relationName: 'frequentlyUsedEndTerminals',
				values: frequentlyUsedTerminals
			})
		}

		return store.getters['users/peekRelation']('me', 'frequentlyUsedEndTerminals')
	}

	public static async fetchFrequentlyUsedCargoTypesLazy(): Promise<Array<CargoType>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['users/hasRelationCached']('me', 'frequentlyUsedCargoTypes')) {
			const frequentlyUsedCargoTypes = (
				(await (await User.getCurrentUser()).frequentCargoTypes().get()) as PluralResponse
			).getData() as Array<CargoType>

			store.commit('users/cacheRelationship', {
				id: 'me',
				relationName: 'frequentlyUsedCargoTypes',
				values: frequentlyUsedCargoTypes
			})
		}

		return store.getters['users/peekRelation']('me', 'frequentlyUsedCargoTypes')
	}

	private vessels(): ToManyRelation {
		return this.hasMany(Vessel, 'vessels')
	}

	public getVessels(): Array<Vessel> {
		return this.relation('vessels') || []
	}

	public vesselLogs(): ToManyRelation {
		return this.hasMany(VesselLog, 'vesselLogs')
	}

	public getVesselLogs(): Array<VesselLog> {
		return this.relation('vesselLogs') || []
	}

	public fleetMembers(): ToManyRelation {
		return this.hasMany(FleetMember, 'fleetMembers')
	}

	public getFleetMembers(): Array<FleetMember> {
		return this.relation('fleetMembers') || []
	}

	public insightsFleetMembers(): ToManyRelation {
		return this.hasMany(FleetMember, 'insightsFleetMembers')
	}

	public getInsightsFleetMembers(): Array<FleetMember> {
		return this.relation('insightsFleetMembers') || []
	}

	public company(): ToOneRelation {
		return this.hasOne(Company, 'company')
	}

	public getCompany(): Company | null {
		return this.relation('company') || null
	}

	private financialConnection(): ToOneRelation {
		return this.hasOne(FinancialConnection, 'company')
	}

	public getFinancialConnection(): FinancialConnection | null {
		return this.relation('financialConnection') || null
	}

	public messages(): ToManyRelation {
		return this.hasMany(Message, 'messages')
	}

	public offers(): ToManyRelation {
		return this.hasMany(Offer, 'offers')
	}

	public cargoProjects(): ToManyRelation {
		return this.hasMany(CargoProject, 'cargoProjects')
	}

	public historicOffers(): ToManyRelation {
		return this.hasMany(Offer, 'historicOffers')
	}

	public activeEmptyVessel(): ToOneRelation {
		return this.hasOne(EmptyVessel, 'activeEmptyVessel')
	}

	public getActiveEmptyVessel(): EmptyVessel | null {
		return this.getRelation('activeEmptyVessel') || null
	}

	public queuedEmptyVesselReport(): ToOneRelation {
		return this.hasOne(EmptyVessel, 'queuedEmptyVesselReport')
	}

	public getQueuedEmptyVesselReport(): EmptyVessel | null {
		return this.getRelation('queuedEmptyVesselReport') || null
	}

	public relevantOffers(): ToManyRelation {
		return this.hasMany(Offer, 'relevantOffers')
	}

	public frequentStartTerminals(): ToManyRelation {
		return this.hasMany(Facility, 'frequentStartTerminals')
	}

	public frequentEndTerminals(): ToManyRelation {
		return this.hasMany(Facility, 'frequentEndTerminals')
	}

	public frequentCargoTypes(): ToManyRelation {
		return this.hasMany(CargoType, 'frequentCargoTypes')
	}

	public ownedFleets(): ToManyRelation {
		return this.hasMany(Fleet, 'ownedFleets')
	}

	public getOwnedFleets(): Array<Fleet> {
		return this.getRelation('ownedFleets') || []
	}

	public memberships(): ToManyRelation {
		return this.hasMany(Membership, 'memberships')
	}

	public getMemberships(): Array<Membership> {
		return this.getRelation('memberships') || []
	}

	public setMemberships(memberships: Array<Membership>): void {
		return this.setRelation('memberships', memberships)
	}

	public favouriteVesselGroups(): ToManyRelation {
		return this.hasMany(FavouriteGroup, 'favouriteVesselGroups')
	}

	public favouriteBrokerGroups(): ToManyRelation {
		return this.hasMany(FavouriteGroup, 'favouriteBrokerGroups')
	}

	public latestLoadingBill(): ToOneRelation {
		return this.hasOne(BillOfLading, 'latestLoadingBill')
	}

	@Property()
	public email!: string

	@Property()
	public emailInvoicing!: string

	@Property()
	public password!: string

	@Property()
	public firstName!: string

	@Property()
	public lastName!: string

	public get fullName(): string {
		return [this.firstName, this.lastName].filter(Boolean).join(' ')
	}

	@Property('userRoles')
	public role!: string

	@Property()
	public countryId!: number

	@Property('locale')
	public language!: string

	@Property()
	public companyName!: string | undefined

	@Property()
	public newMessages!: Array<{ amountUnreadMessages; data: any }>

	@Property()
	public hasVesselLogPermission!: boolean

	@Property()
	public isSuperUser!: boolean

	@Property()
	public newOffersRelatedMessages!: number

	@Property()
	public newTransportsRelatedMessages!: number

	@Property()
	public newFleetInvitations!: number

	@Property()
	public phoneNumbers!: Array<string>

	@Property('isTermsAndConditionsAccepted')
	public termsAndConditionsAccepted!: boolean

	@Property('isPrivacyStatementAccepted')
	public privacyStatementAccepted!: boolean

	@Property('isActive')
	public active!: boolean

	@Property()
	public notifyNewMessage!: boolean

	@Property()
	public notifyNewBidOnCargo!: boolean

	@Property()
	public notifyCargoOfferedToVessel!: boolean

	@Property()
	public notifyAddedToGroup!: boolean

	@Property()
	public notifyNewRelevantOffer!: boolean

	@Property()
	public notifyCharterAccepted!: boolean

	@Property()
	public notifyCharterRejected!: boolean

	@Property()
	public notifyCharterGenerated!: boolean

	@Property()
	public notifyCargoOfferedToBroker!: boolean

	@Property()
	public vessel!: { tonnage: number; length: number; name: string }

	@Property()
	public readonly spoofed?: boolean = false

	@Property()
	public readonly isTestAccount?: boolean = false

	public hasMembership(slug: string): boolean {
		return this.getMemberships().some((membership) => membership.slug === slug)
	}

	public static async getCurrentUser(): Promise<User> {
		const store = (await import('@/store/index')).store
		// @ts-ignore
		const state = store.state.auth
		const currentUser = new User()
		currentUser.setApiId(state.user.id)
		currentUser.firstName = state.user.firstName
		currentUser.lastName = state.user.lastName
		currentUser.email = state.user.email
		currentUser.companyName = state.user.companyName
		currentUser.role = state.user.role
		currentUser.active = state.user.active

		return currentUser
	}

	public static async getCurrentVessels(): Promise<Array<Vessel>> {
		const store = (await import('@/store/index')).store
		// @ts-ignore
		return Promise.all(
			store.state.auth.vessels.map(async (vesselId) => {
				const cachedVessel = store.getters['vessels/peekRecord'](vesselId)
				if (cachedVessel) {
					return cachedVessel
				}
				await store.dispatch('vessels/findRecord', vesselId)
				return store.getters['vessels/peekRecord'](vesselId)
			})
		)
	}

	public static async getPrimaryVessel(): Promise<Vessel> {
		return (await User.getCurrentVessels())[0]
	}
}
