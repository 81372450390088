export default {
	auth: {
		base: {
			email: 'E-mail address',
			password: 'Password',
			register: 'Register',
			login: 'Log in',
			logout: 'Log out',
			'my-details': 'My details',
			preferences: 'Preferences'
		},
		login: {
			forgot_password: 'Forgot your password',
			create_account: 'No account yet?',
			activated: 'Your account has been successfully activated. You can now log in with your login details.',
			activated_invalid: 'Your account has already been activated or your token has expired.',
			user_not_found: 'This user was not found',
			user_not_authorized: 'Your e-mail or password is incorrect'
		},
		forgot_password: {
			header: 'Forgot your password',
			success_notification:
				'Within a few moments you will receive an e-mail with instructions to reset your password'
		},
		reset_password: {
			header: 'Set new password',
			success_notification: 'Your password has been changed'
		},
		register: {
			header: "It's time to join the future... which begins today!",
			role: 'What role do you have?',
			company: 'Company name',
			login: 'Login',
			termsAndConditions: 'Terms and conditions',
			termsAndConditionsText: 'Terms and conditions text',
			privacyStatement: 'Privacy declaration',
			privacyStatementText: 'Privacy statement text',
			success: 'Good luck',
			successText: 'The registration was successful, check the inbox of your e-mail for an activation link!'
		},
		add_company: {
			header: 'Register your company so that you can use all that fantastic functionality!',
			notification:
				'4shipping assesses your additional registration within 2 working days. You will be notified if you get access to the protected part of the platform'
		},
		add_vessel: {
			header: 'Register your ship so that you can see the cargo offer!'
		}
	},
	base: {
		at: 'at',
		country: 'Country',
		terminals: 'Terminals',
		berths: 'Berths',
		close: 'Close',
		phone_number: 'Telephone number',
		email: 'E-mail',
		website: 'Website',
		opening_hours: 'Opening hours',
		yes: 'yes',
		no: 'no',
		left: 'left',
		right: 'right',
		open: 'Open',
		closed: 'Closed',
		locks: 'Locks',
		height: 'Height',
		length: 'Length',
		width: 'Width',
		max_width: 'Max. width',
		phoneNumber: 'Telephone number',
		address: 'Address',
		operatingHours: 'Operating times',
		maxDimensions: 'Max. Dimensions',
		administrator: 'Administrator',
		name: 'Name',
		number: 'nr. {0}',
		next: 'Next one',
		note: 'Comment',
		suffix_meters_short: 'm',
		suffix_hours_short: 'u',
		suffix_centimeters_short: 'cm',
		suffix_kilometers_short: 'km',
		suffix_grams: 'gram',
		suffix_hours: 'hour',
		suffix_minutes: 'min',
		suffix_minutes_short: 'm',
		suffix_days: 'days',
		suffix_days_short: 'd',
		suffix_day: 'dag',
		suffix_cost_per_ton: '\u20ac/ton',
		suffix_tons: 'ton',
		suffix_kg: 'kg',
		suffix_cubic_meters: 'm3',
		suffix_cubic_meters_per_second: 'm3/s',
		distance: 'Distance',
		duration: 'Duration',
		agreed: 'Agreed',
		ok: 'Okay',
		bridges: 'Bridges',
		allow: 'Allow',
		deny: 'Refuse',
		save: 'Save',
		submit: 'Send',
		cancel: 'Cancel',
		car_scaffolding: 'Car scaffolding',
		bunker_stations: 'Bunker stations',
		water_levels: 'Water level',
		ports: 'Ports',
		route: 'Route',
		more: 'More',
		'thank-you': 'Thanks',
		relative_to_nap: 'relative to NAP',
		on_date: 'on {date}',
		origin: 'Departure point',
		destination: 'Destination',
		today: 'Today',
		table: 'Table',
		chart: 'Chart',
		roles: {
			shipper: 'Shipper',
			skipper: 'Skipper',
			logisticserviceprovider: 'Logistic Service Provider',
			portmanager: 'Port Manager',
			client: 'Client',
			carrier: 'Carrier'
		},
		compass: {
			north: 'north',
			northeast: 'north-east',
			east: 'east',
			southeast: 'south-east',
			south: 'south',
			southwest: 'south-west',
			west: 'west',
			northwest: 'north-west'
		},
		subjectedTo: 'Under approval',
		vhf: 'VHF',
		prefered: 'Desirable',
		mandatory: 'Necessary',
		language: 'Language',
		back: 'Back',
		coming_soon: 'Coming soon',
		file_uploaded: 'File uploaded',
		suffix_teu: 'TEU',
		delete: 'Delete',
		week: 'Week',
		suffix_tonnage: 'tonnes'
	},
	ais_position: {
		title: 'May we know your AIS position?',
		description: 'To serve you better, we need access to your AIS position. For this we need the MMSI Number.',
		mmsi_number: 'MMSI Number',
		approve: 'I give 4Shipping access to my AIS Position.'
	},
	contact: {
		improvement: 'Feedback for improvement',
		bug_partial_blocking: 'Bug partially blocking me',
		bug_fully_blocking: 'Bug completely blocking me',
		explain_functionality: 'Get explanation about functionality',
		title: 'Contact',
		description: 'With this form you can ask a question or send feedback to 4shipping.',
		label: 'Question or feedback',
		label_long: 'Question/comment to 4shipping?',
		success: 'Sending your question/comment was successful.',
		error: 'Something went wrong while sending your question/comment. Please try again later.'
	},
	errors: {
		generic: 'Something went wrong in communicating with the server. Try again later.',
		forbidden: 'You have no rights to view this page',
		unknown: 'An unknown error has occurred.',
		not_found: 'The requested page cannot be found.',
		form_errors: 'Error message(s):',
		upload_errors: 'Something went wrong while uploading the file. Try again later.',
		download_errors: 'Something went wrong while downloading the file. Try again later.',
		active_pending: {
			header: 'Your additional registration is pending',
			body: 'You will receive a message about this'
		},
		redirect_inactive: 'Additional information regarding your registration is needed.',
		chunkLoadError: 'A new version is available, the window needs to be refreshed'
	},
	login: {
		header: 'Beta version',
		email: 'E-mail address',
		password: 'Password',
		button: 'Log in',
		register: 'Register',
		forgot_password: 'Forgot your password?',
		create_account: 'No account yet?'
	},
	map: {
		showOptions: 'Show other search options',
		hideOptions: 'Hide other search options',
		vesselLength: 'Vessel length',
		vesselWidth: 'Vessel width',
		vesselHeight: 'Vessel height',
		maxLoadCapacity: 'Max. load capacity',
		loadedQuantity: 'Loaded quantity',
		draught: 'Draught',
		exploitation: 'Exploitation',
		withMultipleRoutes: 'Include alternative routes',
		withAspect: '{0} are included in calculations',
		operatingTimes: 'Operating times',
		closures: 'Closures',
		tides: 'Tide',
		withEmptyReturn: 'Include empty return',
		loadingTimeHours: 'Include loading time',
		unloadingTimeHours: 'Include unloading time',
		routeType: {
			title: 'Route type',
			best_route: 'best route',
			whole_network: 'ship 38*5*2*1.5 (whole network)',
			smaller_ship: 'ship 20% smaller (L.W.H.D)',
			shortest: 'shortest route (<km)',
			without_ftm_messages: 'without FTM messages',
			without_operating_times: 'without operating times (24/7)',
			start_later: 'start 3h later'
		},
		routeOptimizationType: {
			title: 'Route optimization type',
			km: 'Km',
			time: 'Time',
			cost_per_ton: 'Cost per ton'
		},
		feedback: 'Give feedback about this route',
		message: 'Click different routes to view the details.',
		categories: {
			header: 'Traffic information for inland shipping'
		},
		directions: {
			suffix_number_of_locks: 'Locks',
			no_route_found: 'No route is possible between this departure point and destination',
			current_location: 'Current location ({vessel})',
			current_location_geolocation: 'Current location (device)',
			'waterway-dimensions': 'The size of your ship is too big or too wide for this part of the route',
			waterway_dimensions: {
				length: 'The length of your barge exceeds the limits this part of the route. Meters too wide:',
				width: 'The width of your barge exceeds the limits for this part of the route. Meters too wide:',
				draught: 'The draught of your barge exceeds the limits for this part of the route. Meters too deep:',
				'waterway-dimensions': 'The size of your barge is too big or too wide for this part of the route'
			},
			limits: 'Maximum dimensions for this route',
			limits_ww: 'Waterway limitation:',
			limits_kw: 'Art work restriction:',
			limits_with_draught: '{length}m length, {width}m width, {draught}m draught',
			limits_with_height: '{length}m length, {width}m width, {height}m height',
			limits_length: '{quantity}m length',
			limits_width: '{quantity}m width',
			limits_height: '{quantity}m height',
			limits_draught: '{quantity}m draught',
			costs: {
				title: 'Costs',
				total: 'Total costs:',
				costsPerTon: 'Costs per ton:',
				fuel: 'Fuel costs:',
				personnel: 'Personnel costs:',
				material: 'Material costs:',
				port: 'Port fees:',
				disclaimer:
					'Insights are an indication. No rights can be derived from these insights. 4shipping accepts no liability.'
			}
		},
		'inaccessible-route': 'Pay attention! The route is (partially) inaccessible'
	},
	navigation: {
		beta: 'Beta',
		dashboard: 'Overview',
		map: 'Maps',
		cargo: 'Cargo',
		capacityRadar: 'Capacity',
		relation: 'Relation',
		transport: 'Transport',
		more: 'More',
		messages: 'Messages',
		groups: 'Groups',
		fleet: 'Fleet',
		dashboardInsights: 'Insight',
		intermodal_booking: 'Book container',
		loading_bill: 'Make loading bill',
		financial: 'Financial',
		terminals: 'Terminals',
		depot: 'Depot'
	},
	terminals: {
		user_managed: 'The data of this terminal is managed by your company',
		harbour_title: 'Port/Canal/River',
		harbour: 'Port/Channel',
		harbour_number: 'Port number',
		location_code: 'UN location',
		transfer_type_title: 'Handling type',
		can_transfer_container: 'Container handling',
		can_store_container: 'Container storage',
		can_transfer_bulk: 'Bulk handling',
		can_store_bulk: 'Bulk storage',
		can_transfer_piece_goods: 'General cargo handling',
		can_store_piece_goods: 'General cargo storage',
		quay_length: 'Quay length',
		specifications_title: 'Specifications',
		comments: 'Comments',
		is_public: 'Registered'
	},
	cargo: {
		title: 'Freight offer',
		search: 'Search...',
		drybulk: 'Bulk',
		containers: 'Containers',
		liquid: 'Liquid',
		breakbulk: 'Piece good',
		clean_slate: {
			title: 'Cargo',
			headline: 'Gain insight into transport options',
			add_cargo_button: 'Add new cargo',
			text_main: 'Start by creating cargos and planning or tendering.',
			text_api: 'Do you want to create cargos automatically? That is possible via our API.'
		},
		price_units: {
			block: 'Block',
			per_ton: 'ton',
			per_teu: 'TEU',
			per_cubic_meter: 'm3',
			ton: 'ton',
			per_day: 'per day',
			per_hour: 'per hour',
			per_week: 'weekly',
			per_piece: 'Piece'
		},
		options: {
			example: 'For example',
			estimated_freight_price: 'Estimated freight price',
			estimated_commission: 'Estimated commission',
			estimated_total_costs: 'Estimated total costs',
			estimated_total_revenue: 'Estimated total revenue',
			has_gasoil_addition: 'Fuel surcharge',
			fuel_index: {
				label: 'Index',
				kbn: 'KBN',
				pjk: 'PJK',
				fod: 'FOD Economie'
			},
			berth_fee_quantity: {
				ton: 'Ton',
				cubic: 'Cubic',
				per_piece: 'Piece'
			},
			berth_fee_time: {
				per_hour: 'Per hour',
				per_day: 'Per day',
				per_half_day: 'Per half day',
				per_eight_hours: 'Per eight hours',
				per_six_hours: 'Per six hours'
			},
			custom_berth_fee: {
				label: 'Custom demurrage',
				load_label: 'Custom load demurrage',
				unload_label: 'Custom unload demurrage',
				amount: 'Amount',
				quantity_unit: 'Quantity unit',
				time: 'Time'
			},
			payment_term_condition_options: {
				upon_receipt_of_invoice_and_freight_documents: 'Upon receipt of invoice and freight documents',
				after_invoice_date: 'After the invoice date',
				upon_unloading: 'Upon unloading',
				upon_loading: 'Upon loading',
				after_bill_of_lading_date: 'After bill of lading date'
			},
			measured_time: {
				label: 'Measurement time',
				loading_day: 'Day of loading',
				unloading_day: 'Day of unloading',
				monday: 'Monday',
				average_last_month: 'Average last month',
				average_last_half_month: 'Average last half month',
				specific_date: 'Specific date',
				first_day_of_month: 'First day of month'
			},
			calculation_method: {
				label: 'Calculation over',
				loading_weight: 'Loaded quantity',
				unloading_weight: 'Unloaded quantity',
				price: 'Freight price'
			},
			threshold: 'Threshold',
			step_size: 'Step size',
			amount_per_step: 'Amount per step',
			certificationIsBRL: 'BRL',
			delivery_terms: 'Delivery terms',
			applicabilityClaim: 'Applicability',
			carrier: 'Carrier',
			client: 'Client',
			approximateCargoSize: 'Approx.',
			addition_height_meters: 'Water level in meters',
			addition_height: 'Water level',
			addition_percentage: 'Percentage',
			berth_fee: 'Demurrage',
			block: 'Block',
			cargo_hold_floor: 'Cargo space floor',
			cargo_hold_floor_demand: 'Cargo space floor requirement',
			cargo_insured_by: 'Cargo insured with:',
			charter_type: 'Charter type',
			combined: 'Combined',
			comments: 'General remarks',
			price_comments: 'Price remarks',
			cover_cargo_while_shipping: 'Transport covered',
			cubic: 'Cubic meters',
			custom_dossier_number: 'Dossier number',
			end: 'Maximum',
			extra_options: 'Extra options',
			financial: 'Financial',
			high_tide_costs_apply: 'HWZ (high water surcharge)',
			iam_expert: 'I am an expert',
			enable_charter_details: 'Generate charter (for transporter)',
			charter_details: 'Charter details',
			close_charter_details: 'Close charter details',
			enable_order_details: 'Generate confirmation of order (for customer)',
			order_details: 'Order details',
			client_order_reference: 'Customer purchase order number',
			client_reference: 'Customer reference',
			client_order_reference_short: 'PO number',
			product_remark: 'Remark',
			via_broker: 'Via broker',
			ice_costs: 'Blockage/ice surcharge',
			is_certification_required: 'Certification requirement',
			has_last_transported_cargos: 'Last transported cargos',
			has_legal_basis: 'Transport legal basis',
			is_insurance_required: 'Ship insurance required',
			is_subjected_to_empty_vessel: 'Subject to previous trip',
			has_gasoil_surcharge: 'Gasoil surcharge',
			keep_it_simple: 'I keep it simple',
			legal_basis: 'Transport legal basis',
			load_and_unload_time: 'Loading and unloading time',
			load_berth_fee: 'Loading demurrage',
			load_time_condition: 'Loading conditions',
			load_on: 'Load on',
			load_time: 'Charging time',
			load_unload_conditions: 'Load/unload conditions',
			loaded: 'Loaded tons',
			loadedQuantity: 'Loaded quantity',
			loading_place: 'Loading place',
			low_tide_costs_apply: 'KWZ (low water surcharge)',
			low_tide_addition_time: 'loading day 5.00 AM',
			payment_over: 'Payment over',
			per_unit: 'Per unit',
			price: 'Price',
			price_validation: 'The price field is mandatory',
			product: 'Product',
			provision: 'Commission',
			rental: 'Rent',
			separately: 'Separate',
			not_applicable: 'Not applicable',
			shipper: 'Shipper',
			makeQuotation: 'Quotation',
			skipper: 'Skipper',
			fuel_costs: 'Fuel costs paid by:',
			port_costs: 'Port costs paid by:',
			start: 'Minimum',
			'submit-cargo': 'Choose transporter',
			'submit-concept': 'Save as draft',
			target_price: '(Target)price',
			ton: 'Ton',
			piece: 'Piece',
			to: 'to',
			up_to: 'to',
			transfer_time: 'Minimum sailing time',
			unload_berth_fee: 'Releasing demurrage',
			payment_term_condition: 'Payment term conditions',
			unload_time_condition: 'Unloading time conditions',
			load_unload_time_condition: 'Loading and unloading time conditions',
			unload_on: 'Unload on',
			unload_on_sundays: 'Sundays and public holidays sailing/loading/unloading',
			unload_on_sundays_not_required: 'Required',
			unload_on_sundays_required: 'Not required',
			unload_time: 'Release time',
			unloaded: 'Unloaded tons',
			unloadedQuantity: 'Unloaded quantity',
			unloading_place: 'Unloading place',
			container_status: {
				full: 'Full',
				empty: 'Empty',
				mix: 'Mix',
				mixClarified: 'Full and empty'
			},
			must_have_reefer_connection: 'Reefer connections',
			reefer_number: 'Number of reefer connections',
			must_have_adn: 'ADN certification (for hazardous substances)',
			amount_of_cones: 'Number of cones',
			max_amount_layers: 'Max. number of layers in the ship',
			is_subjected_to_stability: 'Stability, tonnage (on water level) and weather conditions',
			number_of_terminals: 'Number of terminals',
			start_terminals_quantity: 'Number of terminals at loading location',
			destination_terminals_quantity: 'Number of terminals at unloading location',
			load_on_water_level: 'Loading on water level',
			load_up_to_amount: 'A full load of up to specified amount',
			teu_quantity_min: 'Minimum number',
			teu_quantity_max: 'Maximum number',
			mandatory_field: 'This is a required field',
			end_date_travel_period_applies: 'An end of travel period applies',
			end_date_travel_period: 'End of travel period',
			end_date_rental_period_applies: 'An end of rental period applies',
			end_date_rental_period: 'End of rental period',
			provide_loading_time: 'Provide time',
			is_reporting_date: 'Report on',
			loading_time: 'Loading time',
			no_unload_date: 'No unloading date? Then the skipper reports on arrival',
			termOfPaymentDays: {
				label: 'Term of payment',
				unit: 'days'
			},
			productLastTransported: '1st product last transported',
			productSecondLastTransported: '2nd product last transported',
			productThirdLastTransported: '3rd product last transported',
			holdCleaningCode: 'Hold cleaning code',
			copy_charter_details: 'Copy data from charter details',
			required_draughts: {
				title: 'Required draughts',
				pegel: 'Pegel',
				draught: 'draught (m)',
				add: 'Add draught'
			},
			or: 'OR',
			loadTimeMethod: {
				label: 'Load',
				time: 'Time',
				condition: 'Condition',
				guaranteed: 'Guaranteed full at'
			},
			unloadTimeMethod: {
				label: 'Unload',
				time: 'Time',
				condition: 'Condition',
				guaranteed: 'Guaranteed empty at'
			},
			before_leave:
				'Pay attiontion! This cargo has not been finalised. Are you sure you want to leave this page?',
			delivery_method: 'Delivery method'
		},
		compare_transport_options: 'View transport options',
		cost_per_ton: 'Costs',
		co2_output: 'CO2',
		nox_output: 'NOx',
		fuel_usage: 'Fuel',
		pm_output: 'PM',
		saved_successfully: 'We will get you in touch with a carrier as quickly as possible.',
		offers: {
			search: 'Search...',
			header: 'Find the best cargo',
			'no-offers': 'There are no public loads available at this time.',
			'vessel-empty-header': {
				title: 'Register empty vessel',
				title_skipper: 'Sign up empty so we can find new loads',
				edit: 'Edit empty vessel report'
			},
			'no-historic-offers': 'You have not responded to a cargo offer before.',
			show_historic_offers: 'Show historical cargos.',
			sections: {
				order: 'Orders',
				tender: 'Tender',
				actions: 'Actions'
			},
			headers: {
				projectName: 'Project name',
				dossierNumber: 'Dossier number',
				client: 'Client',
				product: 'Product',
				quantity: 'Quantity',
				loadingLocation: 'Loading location',
				unloadingLocation: 'Unloading location',
				loadingTime: 'Loading',
				unloadingTime: 'Unloading',
				offerUntil: 'Tender open',
				offerInterest: 'Interests',
				actions: 'Actions'
			},
			project: {
				noResults: 'This project currently has no cargo orders.'
			}
		},
		offered: {
			projects: 'Projects',
			header: 'Freight',
			add_cargo_button: 'Add cargo',
			delete_cargo_button: 'Delete cargo',
			copy_cargo_button: 'Duplicate cargo',
			continue_cargo_button: 'Continue with previous cargo',
			show_historic_cargo: 'Show historical cargos',
			no_historic_cargo: 'You have no expired cargos yet.',
			own: 'Own cargo',
			external: 'External cargo',
			'capacity-radar': 'Capacity radar'
		},
		capacityRadar: {
			clean_slate: {
				headline: 'Membership needed',
				request: 'Do you want to search vessels by empty vessel report or vessel attributes?',
				contact_us: 'Contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40 or use the contact form in the app.'
			},
			table: {
				sections: {
					ship: 'Ship',
					empty: 'Empty',
					crm: 'CRM',
					actions: 'Actions'
				},
				headers: {
					vesselName: 'Vessel name',
					tonnage: 'Tonnage',
					capacityCubicMeters: 'm3',
					length: 'Length',
					width: 'Width',
					date: 'Date',
					location: 'Location',
					remark: 'Remark',
					spotmarket: 'Spotmarket',
					via: 'Via',
					lastContact: 'Last contact',
					actions: 'Actions',
					geographicAreas: 'Geographic areas'
				}
			},
			filterDialog: {
				title: 'Filter potential vessels',
				location: 'Locations',
				geographicArea: 'Geographic Area',
				radius: 'Radius (km)',
				width: 'Width',
				length: 'Length',
				tonnage: 'Tons',
				hasEmpty: 'Has empty report'
			},
			noResults: 'No empty vessel reports found which match the search criteria.',
			actions: {
				showContactCard: 'Contact card',
				removeEmpty: 'Delete empty vessel report',
				editEmpty: 'Edit empty vessel report'
			}
		},
		offer: {
			'no-bids': 'No bid',
			'find-empty-ship': 'Find empty ship',
			message: 'Message(s)',
			'offer-interest': 'Call request',
			draft: 'Draft',
			'ship-offered': 'offered',
			'no-price': 'without quotation'
		},
		clients: {
			title: 'Customer',
			placeholder: 'Choose a customer'
		},
		publication: {
			bidReviewNotRequired: 'Falls under framework contract',
			favouriteGroupCreatorTitle: {
				brokers: 'New favourite charterer group',
				vessels: 'New favourite group'
			},
			menu: {
				schedule: 'schedule',
				tender: 'Tender'
			},
			place_longer_search_brokers: 'Enter a search term of at least 3 letters to search for charterers',
			place_search_brokers: 'Enter a search term to search for charterers',
			via_broker: 'Via broker',
			has_charter: 'A charter will be generated.',
			no_charter: 'No charter will be generated.',
			has_order: 'A confirmation of order will be generated.',
			no_order: 'No confirmation of order will be generated.',
			cannot_tender_no_terms: 'Tendering is not possible without charter terms.',
			saved_successfully: 'We have successfully published your cargo offer!',
			title: 'Offer',
			spot_market: 'Direct skippers (spot market)',
			all: 'All of them',
			favourite_group: 'Favorite group',
			favourite_group_label: 'This will only be shared with registered vessels.',
			no_group: 'You have no favorite groups yet. Click here to create your first group.',
			phone_number: 'Show my phone number with this offer',
			offer_until: 'Offer up to:',
			offer_now: 'Offer now',
			tender_now: 'Publish tender',
			plan_now: 'Create contract + schedule',
			create_contract_now: 'Show contract preview',
			favourite_group_placeholder: 'Choose favorite group',
			select_vessel: 'Select ship',
			specific_vessel: 'Schedule a known vessel',
			specific_broker_or_vessel: 'Schedule a known charterer or vessel',
			find_vessel: 'Search by (ship) name',
			find_name: 'Search by name',
			planned_successfully: 'Your cargo has been scheduled!',
			edit_publication_date: 'Change publication date',
			date_editted_succesfully: 'The publication end date of your cargo offer has changed!',
			fleet_placeholder: 'Choose a barge from your fleet',
			colleague_placeholder: 'Choose a colleague',
			client_placeholder: 'Choose a client',
			fleet_vessel: 'Choose a barge from your own fleet',
			no_results: 'No results found',
			place_search: 'Place a search term to search barges',
			place_longer_search: 'Place a search term of minimum of 3 characters to search barges',
			click_to_create: 'Create a new barge',
			vessels_hint: 'These can only be registered vessels with an account.',
			canCollaborate:
				'You can collaborate with this skipper with the platform because this skipper has an account.',
			viaBrokerRegisteredVessel:
				"You are scheduling a registered vessel via another broker. Because it is scheduled via a broker we don't share any information with the skipper (which is normally the case).",
			brokers: {
				title: 'Logistic service providers',
				all: 'All',
				favourite_group: 'Favorite group',
				none: "Don't offer",
				allPanel: {
					collapsed: 'Show logistic service providers',
					expanded: 'Hide logistic service providers',
					subtitle: 'Logistic service providers'
				},
				favourite: {
					no_membership: 'This is pro-functionality. Sign up for a subscription via {0}.'
				}
			},
			skippers: {
				title: 'Direct skippers (spotmarket)',
				all: 'All',
				favourite_group: 'Favorite group',
				none: "Don't offer",
				add: 'Add'
			},
			before_leave: 'Pay attiontion! This cargo has not been finalised. Are you sure you want to leave this page?'
		},
		show: {
			schedule: 'Schedule',
			header: 'Freight offer',
			expires_at: 'Expires in',
			expired: 'This offer has expired.',
			has_transport: 'A transport has already been created.',
			loaded_weight: 'Loaded weight',
			unloaded_weight: 'Unloaded weight',
			cargo_by: 'Charge by',
			not_based_on_emptiness: 'Not without prejudice of availability',
			based_on_emptiness: 'Without prejudice of availability',
			incl_sundays: 'including Sundays/public holidays',
			without_sundays: 'Sundays/public holidays not required',
			price: '(Target)price',
			address: 'Address',
			city: 'City',
			comments: 'Remarks',
			cover_sheet: 'Cover sheet',
			client: 'Customer',
			creator: 'Client',
			condition_preference_loading: 'Load conditions',
			condition_preference_unloading: 'Discharge conditions',
			conditions_not_applicable: 'Not applicable',
			load: 'Load',
			load_period: 'Load term',
			load_time: 'Load time',
			load_time_guaranteed_at: 'Guaranteed full',
			unload_time_guaranteed_at: 'Guaranteed empty',
			load_berth_fee: 'Loading demurrage',
			unload: 'Unload',
			unload_period: 'Unloading term',
			unload_time: 'Unload time',
			unload_berth_fee: 'Releasing demurrage',
			load_up_to_amount: 'A full load of up to',
			port_costs: 'Port costs',
			print: 'Print',
			channel_costs: 'Channel costs',
			fuel_costs: 'Fuel costs',
			working_days: 'Business days',
			affordable: 'Affordable',
			insurance: 'Insurance',
			available: 'Available',
			required: 'Requires',
			name: 'Name',
			loaded_quantity: 'Loaded quantity',
			unloaded_quantity: 'Unloaded quantity',
			delete: 'Delete',
			transfer_time: 'Required sailing time',
			commission_percentage: 'Commission',
			commission_percentage_extra: 'none',
			report_upon_arrival: 'Report on arrival',
			edit_date: 'Change date',
			edit_cargo: 'Edit',
			edit_expiry_date: 'Change the end date',
			error_has_invoice: 'The transport has a closed invoice and therefor it cannot be deleted.',
			end_date_rental_or_travel: 'End of trip / rental',
			skipper_exclusive_specific_vessel: 'Exclusively for you!',
			skipper_exclusive_favourite_skippers: 'Exclusively for you and some others!',
			skipper_exclusive_favourite_brokers: 'Exclusively for you and some others!',
			shipper_exclusive_specific_vessel: 'Offered to specific barge',
			shipper_exclusive_favourite_skippers: 'Offered to favorite group',
			skipper_exclusive_logistic_service_providers: 'Exclusively for you!',
			shipper_exclusive_logistic_service_providers: 'Offered to broker',
			skipper: 'Skipper',
			vesselName: 'Vessel name',
			gasoil_addition_summary:
				'{index} ({moment}) from {threshold}, {amount} per step (of {stepSize}) over {method}',
			copy_cargo: 'Copy cargo'
		},
		react: {
			make_definitive: 'Make definitive',
			title: 'Make an offer',
			bid: 'Offer',
			call: 'Call',
			message: 'Message',
			submit: 'Place bid',
			block: 'Block',
			per_ton: '\u20ac / ton',
			bidPrice: 'At what price',
			comments: 'Remarks',
			success: 'Your bid has been successfully placed.',
			please_dont_call: 'Skipper does not want to be called. Send a message!',
			not_available: 'Unfortunately not available.',
			enter_your_message: 'Type your message',
			message_send: 'The message has been sent successfully.',
			per_day: '\u20ac / day',
			show_phonenumber: 'Show phone number',
			edit: 'Change',
			deleteConfirmation: 'Are you sure you want to delete the selected bid?',
			we_inform_user: '(We will inform him / her about your interest)',
			submitProposal: 'Propose this barge',
			edit_success: 'Your offer has successfully been changed.',
			minExpected: 'Min',
			maxExpected: 'Max',
			expectedAvailability: 'Expected per',
			isAccessibleForSkipper: 'Give skipper access to cargo details and documents'
		},
		replies: {
			header: 'Reactions',
			offerInterests: 'Call',
			messages: 'Message',
			'no-replies':
				'As soon as there are reactions, they will appear here and you will be notified via a message.',
			'no-replies-skipper':
				'You have not yet responded to this cargo. Click cargo to make an offer or to contact.',
			'definitive-with-vessel': 'Make transport final with a specific ship',
			'plan-now': 'Schedule directly',
			'view-empty-vessel-header': 'Search for available ships',
			'view-empty-vessels': 'View empty ships',
			'charter-ready-skipper': 'Rate the charter',
			'charter-ready-shipper': 'You have generated a charter',
			'bid-made': 'You have made an offer',
			'phone-request': 'You want to handle this by telephone',
			'message-sent': 'You have sent a message',
			'no-replies-historic': 'This offer has expired without any responses.',
			'charter-rejected-skipper': 'You have declined the charter',
			'charter-accepted-skipper': 'You have accepted the charter',
			'charter-accepted-shipper': 'The skipper has accepted your charter',
			'charter-rejected-shipper': 'The skipper has declined your charter',
			'unread-message': 'You have a new unread message',
			'open-messages': 'Open message overview',
			'wait-for-approval': 'Wait for agreement'
		},
		bid: {
			header: 'Bidding',
			tonnage_extra: '(at a depth of 3.20 m)',
			date_of_bid: 'made a bid',
			preview_charter: 'Finalise + create contract'
		},
		preview_charter: {
			header: 'Charter / Order confirmation',
			charter: 'Charter',
			edit: 'Customize',
			finalise: 'Schedule',
			composeMessage: 'Compose message',
			planWithoutMessage: 'Schedule without message',
			download_email_template: 'Download concept email',
			accepted: 'The cargo has been scheduled.',
			accepted_mail_sent: 'The cargo has been scheduled and the contract(s) has been sent.',
			generated_and_downloaded_template: 'The charter is generated and can be sent to the skipper.',
			order: 'Confirmation of order',
			quotation: 'Quotation'
		},
		preview_quotation: {
			header: 'Quotation',
			edit: 'Customize',
			sent: 'The quotation has been sent.',
			createWithoutSend: 'Create without sending',
			composeMessage: 'Compose message',
			accepted: 'The quotation has been created.',
			accepted_mail_sent: 'The quotation has been created and sent to the client.'
		},
		routeplanner: {
			cost_unit: {
				ton: '\u20ac/ton',
				teu: '\u20ac/teu'
			}
		},
		details: {
			'all-replies': 'Reactions',
			negotiations: 'Negotiations',
			cargo: 'Freight',
			clientDossier: 'Client dossier',
			ownDossier: 'Own dossier',
			overview: 'Freight Details',
			dossierNumber: 'Dossier number:',
			clientName: 'Client:'
		},
		reply: {
			up_to: 'to',
			'header-offerInterests': 'Call',
			'header-bids': 'Bidding',
			'header-messages': 'Message',
			tonnage_extra: '(at maximum draft)',
			date_of_bid: 'bid made',
			preview_charter: 'Finalise + create contact',
			date_of_reply: 'this comment has been posted',
			expected_tonnage: 'I expect to be able to carry',
			date_of_loading: 'I expect to be able to load',
			make_definitive: 'Make final',
			make_charter: 'Generate charter',
			accepted_without_charter: 'Proposal accepted'
		},
		empty_vessels: {
			header: 'Available ships',
			'empty-at-extra': '(expected availability)',
			message_sent: 'Your message has been sent',
			'is-anonymous': 'This empty report is anonymous.',
			'create-cargo-for-contact': 'Create a cargo to get in touch with this skipper.'
		},
		mark_vessel_empty: {
			date: 'Date: ',
			location: 'Location: ',
			agreeOnDeletion: 'The following existing empty arrival registration of this vessel will be removed.',
			anonymous: 'Empty notification anonymous',
			empty_expires_at_label: 'Empty notification is being withdrawn automatically at:',
			notify_shippers: 'Report emptiness to clients',
			public: 'Empty notification public',
			search: 'Search',
			register: 'Register',
			search_placeholder: 'Search by terminal or location',
			success_notification: 'Empty notification created',
			edit: 'Change',
			revoke: 'Withdraw availability report',
			remark: 'Remark'
		},
		accept_charter: {
			header: 'Accept charter',
			decline: 'Refuse',
			decline_reason: 'reason',
			decline_placeholder: 'Give a reason...',
			accept: 'Accept',
			accepted: 'The charter has been accepted',
			declined: 'The charter has been rejected'
		},
		attachments: {
			types: {
				advising: 'Transportation information',
				charter: 'Charter',
				orders: 'Confirmation of order',
				'loading-document': 'Loading receipt',
				'unloading-document': 'Unloading certificate',
				'bill-of-lading': 'Bill of lading',
				invoice: 'Invoice',
				other: 'Other file',
				'container-manifest': 'Manifest',
				nomination: 'Nomination',
				'time-sheet': 'Time sheet',
				'transport-confirmation': 'Transport confirmation',
				'transport-order': 'Transport order',
				'stowage-plan-stability-calculation': 'Stowage plan / stability calculation',
				'loading-plan': 'Loading plan',
				'discharge-plan': 'Discharge plan',
				'declaration-of-release': 'Declaration of release',
				'adn-checklist': 'ADN checklist',
				'voyage-preparation': 'Voyage preparation',
				'vessel-data': 'Vessel data',
				'letter-of-protest': 'Letter of protest',
				'customs-document': 'Customs document',
				'temperature-registration': 'Temperature registration',
				'empty-vessel-transport-document': 'Transport document (empty vessel)',
				'building-materials-loading': 'Loading bill building materials',
				'building-materials': 'Unloading bill',
				'bill-of-lading-transport': 'Transport bill of lading',
				'barging-letter-front': 'Barging letter (front)',
				'barging-letter-rear': 'Barging letter rear',
				'contact-card': 'Contact card',
				'hopper-measurement': 'Hopper measurement',
				'measurement-certificate': 'Measurement certificate',
				'lci-report': 'LCI report',
				'chloride-inspection-report': 'Chloride inspection report',
				'purchase-order': 'Purchase order',
				'product-analysis': 'Product analysis'
			},
			camera: 'Camera',
			upload: 'Upload',
			generate: {
				title: 'Upload or generate',
				upload: 'Upload document',
				generate: 'Generate document'
			},
			drop: {
				description: 'Drop your file here to add it to the dossier',
				description_permanent: 'Drop your file here or click on the button',
				button: 'upload'
			}
		},
		projects: {
			vesselTransport: 'Vessel',
			truckTransport: 'Truck',
			header: 'Create project',
			headerEdit: 'Change project',
			submit: 'Create cargo blueprint',
			create: 'Create project',
			edit: 'Continue',
			delete: 'Delete',
			projectName: 'Own reference / project name',
			startDate: 'From',
			endDate: 'Until',
			teu_quantity_min: 'Minimum number of TEU',
			teu_quantity_max: 'Maximum number of TEU',
			tonnage_min: 'Minimum tonnage',
			tonnage_max: 'Maximum tonnage',
			saved_successfully: 'Your project has been created. Continue to create a blueprint.',
			edited_successfully: 'Your project has been changed.',
			blueprint: {
				title: 'Create a draft cargo',
				submit: 'Save blueprint'
			},
			details: {
				title: 'Project',
				projectDetails: 'Project details',
				transports: 'Transports',
				product: 'Product',
				cargoSize: 'Cargo size',
				projectPeriod: 'Project term',
				shippedTonnage: 'Shipped tonnage',
				shippedTeu: 'Shipped TEU',
				edit: 'Change',
				offer: 'Schedule transport',
				volume: 'Volume',
				route: 'Route',
				projectName: 'Project reference',
				receivedReplies: 'Reactions received',
				noReplies: 'No reaction yet'
			}
		}
	},
	locks: {
		number_of_chambers: 'Number of chambers',
		tide_lengths: '(low tide: {lowTide} | high tide: {highTide})',
		sill_depth: 'Threshold depth',
		location: 'Location',
		delays: 'Busy lock passage',
		delay_graph_tooltip: '{delay} minutes delay',
		graph_vertical_axis_title: 'Delay (minutes)',
		east: 'East',
		west: 'West',
		north: 'North',
		south: 'South'
	},
	car_scaffolding: {
		hasWasteDisposal: 'Garbage container',
		hasDrinkingWater: 'Drinking water'
	},
	bunker_stations: {
		hasBunkerBoat: 'Bunker boat present',
		widthPosition: 'Width position'
	},
	berths: {
		maximal_ship_length: 'Maximum ship length',
		minimal_ship_length: 'Minimum ship length',
		moored_ships_length: 'Moored ships length',
		condition: 'Condition',
		note: 'Note',
		length: 'Length',
		width: 'Width',
		current_status: 'Current occupation',
		tooltip_status: '{currentStatus}% full'
	},
	bridges: {
		number_of_openings: 'Number of openings',
		max_height_opened: 'Max. height open',
		max_depth: 'Max. depth',
		current_status: 'Actual status'
	},
	water_levels: {
		reference: 'Reference',
		high_water: 'High tide',
		low_water: 'Low water',
		flow: 'Flow rate',
		temperature: 'Temperature',
		details: 'Other details',
		latest_measurement: 'Last measurement',
		based_on_dutch_nap: 'Water level based on dutch NAP (cm)',
		correction:
			'Determine the correction factor with this slider, to convert the water level to the navigate depth:',
		no_data_available: 'There are no measurements known for this measuring point.',
		adjustment: 'Water level',
		max_depth: 'Maximum draft'
	},
	ports: {
		website: 'Website',
		harbourMasterPhoneNumber: 'Harbour master',
		harbourDuesButton: 'Register harbour fees',
		facilities: 'Facilities'
	},
	search: {
		placeholder: 'Search facility'
	},
	'track-and-trace': {
		client_email: 'Client email',
		skipper_email: 'Skipper email',
		start_terminal_email: 'Start terminal email',
		end_terminal_email: 'End terminal email',
		add_transport_infos: 'Add transport information',
		transport_infos:
			'Transport information: {newline}' +
			'Your reference: {clientOrderReference}{newline}' +
			'Ship name: {shipName} ({dimensions}){newline}' +
			'Skipper name: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'{newline}' +
			'Loading location: {loadingPlace} - {startTerminal}{newline}' +
			'Loading: {loadingTerm}{newline}' +
			'{newline}' +
			'Unloading location: {unloadingPlace} - {endTerminal}{newline}' +
			'Unloading: {unloadingTerm}{newline}' +
			'{newline}' +
			'Product: {productName}{newline}' +
			'Quantity: {quantity}',
		header: 'Share Track & Trace',
		description:
			'Below you can enter the e-mail addresses to whom you want to send the track & trace code. Please type in the e-mail addresses and press the enter key to add them. ',
		email_addresses: 'Email addresses',
		share: 'Share',
		success: 'You have successfully shared your Track & Trace.',
		warning: 'You cannot share your Track & Trace code without an MMSI number.',
		invalid_email: '{email} is not a valid email address',
		comments: 'Comments',
		preview_link: 'Click here for a preview.',
		loading: 'Loading',
		'other-traces': 'Earlier track&traces'
	},
	validation: {
		custom: {
			password: {
				regex: 'The entered password must contain at least one letter, one number and one of the following characters !$#%.'
			},
			mmsiNumber: {
				required: 'The MMSI Number field is required'
			},
			approve: {
				required: 'Agree to continue'
			}
		},
		messages: {
			_default: 'The {0} field is incorrectly filled.',
			required: 'The {0} field is required.',
			regex: 'The entered {0} does not meet the requirements.',
			email: 'The specified email address is invalid.',
			min: 'Enter at least {1} characters.',
			max_value: 'The {0} field can have a maximum value of {1}.',
			max: 'The {0} field has a maximum length of {1} characters.'
		},
		attributes: {
			role: 'role',
			email: 'E-mail address',
			company: 'Company name',
			password: 'password',
			termsAndConditions: 'terms and conditions',
			privacyStatement: 'privacy declaration'
		},
		scroller: {
			title: 'The following fields are not filled correctly, click on the item to scroll to the field.'
		}
	},
	forms: {
		company: {
			address: 'Address *',
			city: 'City name *',
			cocNumber: 'Chamber of Commerce number *',
			companyName: 'Company name *',
			country: 'Country *',
			email: 'E-mail address',
			firstName: 'First name *',
			lastName: 'Surname *',
			hasAlternativeInvoiceDetails: 'I have a different billing address',
			hasAlternativeMailDetails: 'I have a different mailing address',
			phoneCountryCode: 'Country Code *',
			phoneNumber: 'Phone number *',
			postalCode: 'Postcode *',
			vatNumber: 'VAT number *'
		},
		client: {
			address: 'Address',
			city: 'City name *',
			country: 'Country *',
			companyName: 'Company name *',
			email: 'E-mail address *',
			firstName: 'First name *',
			lastName: 'Surname *',
			phoneCountryCode: 'Country Code *',
			phoneNumber: 'Phone number *',
			postalCode: 'Postcode',
			vatNumber: 'VAT number',
			cocNumber: 'Chamber of Commerce number'
		},
		vessel: {
			email: 'Email address *',
			euNumber: 'ENI number (Europe number) *',
			insurance: 'Assurance *',
			length: 'Length (in meters) *',
			name: 'Vessel name *',
			tonnage: 'Tonnage (in tonnes) *',
			width: 'Width (in meters) *',
			draught: 'Depth (in meters) *',
			emptyWeight: 'Empty weight (in tonnes) *'
		},
		unregistered_vessel: {
			capacityCubicMetersUncovered: 'Loading capacity cubic feet (in m3, with bunker valves open)',
			email: 'Email address',
			euNumber: 'ENI number (Europe number)',
			length: 'Length (in meters) ',
			name: 'Vessel name',
			skipperName: 'Skipper',
			tonnage: 'Capacity tonnage (in tonnes)',
			tonnageUncovered: 'Capacity tonnage (in tonnes, uncovered)',
			capacityCubicMeters: 'Capacity cubic meters (in m3)',
			width: 'Width (in meters)',
			draught: 'Depth (in meters)',
			emptyWeight: 'Empty weight (in tonnes)',
			mmsi: 'MMSI number (AIS)',
			gmpNumber: 'GMP number',
			gmpValidUntil: 'GMP valid until'
		}
	},
	messages: {
		overview: {
			header: 'Messages',
			'no-messages': 'There are no messages received.',
			file_upload: 'Document'
		},
		item: {
			'input-placeholder': 'Type a message...',
			userLeft: '{fullName} has left the conversation'
		},
		join: 'Join conversation',
		empty: 'Select a conversation to read the messages.'
	},
	favourite_groups: {
		favouriteGroups: 'Favorite groups',
		vessels: '0 ships | 1 ship | {count} ships',
		brokers: '0 brokers | 1 broker | {count} brokers',
		noGroups: "You don't have any favorite groups yet. Click the button to create your first group.",
		makeNewGroup: 'Create new group',
		newGroupName: 'Name of the new group',
		searchVessel: 'Search based on the vessel name in order to add it to the new group.',
		groupSavedSuccessfully: 'Your new group has been created',
		groupMemberRemoved: 'The group member is removed from the group',
		favouriteGroupMembers: {
			addBrokerMember: 'Add broker',
			addMember: 'New ship',
			noGroupMembers: 'You have no members in this favorite group. Click the button to add members.',
			memberSavedSuccesfully: 'A new user has been added to your group.'
		},
		add: 'Add',
		edit: 'Change',
		groupDeleted: 'The group is deleted.'
	},
	transports: {
		alertMessage: 'Chloride control measurement required!',
		alertCompleted: 'Chloride control measurement required and completed',
		clientDossier: 'View client transport of the current one',
		ownDossier: 'View own transport of the current one',
		createOwnDossier: 'Create own dossier',
		createCombinedInvoice: 'Create combined invoice',
		combinedInvoiceSuccess: 'The combined invoice has been created, you can find it on the financial page.',
		preparePurchaseOrder: 'Prepare purchase invoice order',
		downloadDocument: 'Download documents',
		clientOrderReference: 'Client order reference',
		dossierNumber: 'Dossier number:',
		vesselName: 'Vessel name:',
		cargoType: 'Product:',
		clientName: 'Client:',
		productType: 'Product type:',
		createdByUser: 'Created by:',
		from: 'From:',
		to: 'To:',
		until: 'Until:',
		notifyArrival: 'Notify arrivial',
		unloadingStart: 'I am going to unload',
		notifyFull: 'I am full',
		notifyEmpty: 'I am empty',
		generateDocument: 'Generate document',
		actions: {
			shareTrackAndTrace: 'Share track and trace',
			cargoDetails: 'Cargo details',
			cargoChange: 'Cargo change',
			cargoRemove: 'Cargo remove',
			cargoCopy: 'Cargo copy',
			shipChange: 'Ship change'
		},
		transport: 'Transport',
		search: 'Search...',
		dateFilterType: {
			loading: 'Loading',
			unloading: 'Unloading',
			based_on_terms: 'based on payment over'
		},
		searchByDossierNumber: 'Search by dossier number...',
		searchByVesselName: 'Search by vessel name...',
		searchByCargoType: 'Search by product...',
		searchByClientOrderReference: 'Search by client order reference…',
		searchByClientName: 'Search by client...',
		noTransports: 'You do not have an ongoing transport',
		noHistoricTransports: 'You have no expired transports yet',
		showHistory: 'Show historical transports',
		table: {
			sections: {
				transport: 'Transport',
				actions: 'Actions'
			},
			headers: {
				dossierNumber: 'Dossier number',
				product: 'Product',
				vessel: 'Vessel',
				price: 'Price',
				orderPrice: 'Order price',
				skipper: 'Skipper',
				loading: 'Loading',
				unloading: 'Unloading',
				client: 'Client',
				startLocation: 'Loading location',
				destinationLocation: 'Unloading location',
				documents: 'Documents',
				status: 'Status',
				alert: 'Alert',
				actions: 'Actions'
			},
			unloadingBillAttached: 'Unloading bill in dossier',
			externalInvoiceAttached: 'External invoice in dossier',
			trackTraceSent: 'Track&Trace link sent'
		},
		eta: 'ETA',
		etb: 'ETB',
		etd: 'ETD',
		etaUpdated: 'The ETA has successfully been updated',
		etdUpdated: 'The ETD has successfully been updated',
		editEta: 'Update ETA',
		editEtb: 'Update ETB',
		editEtd: 'Update ETD',
		chatUnavailable: 'Chat is only possible with registered skippers.',
		arrived: 'Arrived',
		arrivalUpdated: 'A new arrival time has ben registered',
		editArrival: 'Update arrival time',
		loading: 'Loading',
		unloading: 'Unloading',
		report_on_arrival: 'Report on arrival',
		loadingLocation: 'Loading location',
		unloadingLocation: 'Unloading location',
		status: {
			finished: 'Finished',
			operational_closed: 'Unloading bill attached',
			checked: 'Checked',
			closed: 'Closed'
		},
		locationStatus: {
			finished: 'Transshipment finished',
			headingTo: 'Sailing',
			loading: 'Loading',
			unloading: 'Unloading',
			arrived: 'Arrived',
			transshipping: 'Transshipping'
		},
		headingToStart: "I'm going to sail",
		loadingStart: "I'm going to load",
		headingToEnd: "I'm going to sail again",
		unloadingEnd: "I'm going to unload",
		finished: 'Cargo has been delivered',
		endOfJourney: 'End of transport',
		checkTransport: 'Transport checked',
		reopenTransport: 'Reopen transport',
		updater: {
			editLogTitle: 'Edit log for {location}',
			etaTitle: 'Record your ETA (Expected Time of Arrival)',
			eta: 'Expected at',
			etbTitle: 'Record your ETD (Expected Time of Start)',
			etb: 'Start at',
			etbUnknown: 'Nothing known yet (no prospects)',
			etbUnknownShort: 'Nothing known yet',
			etbComment: 'Comment',
			etdTitle: 'Record your ETD (Expected Time of Departure)',
			etd: 'Departure at',
			headingToTitle: 'Record the time of start sailing',
			headingToAt: 'Start sailing at',
			arrivalTitle: 'Record the arrival time',
			arrivedAt: 'Arrived at',
			transshippingTitle: 'Record the start time of transshipment',
			transshippingAt: 'Start transshipment at',
			finishedTitle: 'Record the end time of transshipment',
			finishedAt: 'Finished transshipment at',
			loadedQuantity_req: 'Loaded quantity *',
			loadedQuantity: 'Loaded quantity',
			dischargedQuantity_req: 'Unloaded quantity *',
			dischargedQuantity: 'Unloaded quantity',
			draught: 'Draught',
			draughtUnit: 'meter(s)',
			save: 'Opslaan'
		},
		quickStatusUpdater: {
			openFormButton: 'Add loading/unloading data',
			title: 'Add loading/unloading data'
		},
		sharedWithSubContractor: 'Shared with subcontractor',
		fileTags: {
			charter: 'Charter',
			'loading-document': 'Loading receipt',
			'unloading-document': 'Unloading receipt',
			'bill-of-lading': 'Bill of lading',
			invoice: 'Invoice',
			other: 'Other',
			'container-manifest': 'Container Manifest'
		},
		overview: 'Transport overview',
		documents: 'Documents',
		noBillOfLadings: 'This transport currently has no loading or unloading bills',
		billOfLadings: 'Loading and unloading bills',
		loadingBill: 'Loading bill',
		unloadingBill: 'Unloading bill',
		document: {
			share: {
				title: 'Share document',
				cancel: 'Cancel',
				close: 'Close',
				successShared: 'The document is shared with the subcontractor.',
				successUnshared: 'The document is now hidden for the subcontractor.',
				noFileSelected: 'No document selected.',
				unregisteredVessel: {
					shared: 'This document is already shared with the subcontractor via email.',
					notShared: 'Are you sure to share this document with the subcontractor via email?',
					confirmSharing: 'Send'
				},
				vessel: {
					shared: 'Do you want to withdraw sharing this document with the subcontractor?',
					notShared: 'Are you sure you want to share this document with the subcontractor?',
					confirmSharing: 'Share',
					confirmUnsharing: 'Withdraw'
				},
				transport: {
					own: 'Copy to external transport',
					external: 'Copy to own transport',
					success: 'File has been copied',
					error: 'Copying file has failed',
					shared: 'This document is already shared with the client',
					notShared: 'Are you sure you want to share this document with the client?'
				}
			},
			shared: {
				withSubContractor: 'With subcontractor',
				notShared: 'Not shared'
			},
			deleteSuccess: 'The document is successfully removed.'
		},
		shipDetails: {
			shipDetails: 'Barge details',
			dimensions: 'Size',
			tonnage: 'Tonnage',
			draught: 'Draught',
			showContactCard: 'Show contact card',
			editVessel: 'Edit vessel'
		},
		contactDetails: {
			contactDetails: 'Contact details',
			companyName: 'Company Name',
			name: 'Name',
			phoneNo: 'Telephone number',
			email: 'E-mail'
		},
		logDetails: {
			logDetails: 'Log details',
			startPlace: 'Origin',
			destinationPlace: 'Destination',
			eta: 'ETA',
			etb: 'ETB',
			etd: 'ETD',
			headingToDatetime: 'Time start sailing',
			arrivalDatetime: 'Time of arrival',
			transshippingDatetime: 'Time start transshipment',
			finishedDatetime: 'Time finished transshipment',
			loadedQuantity: 'Loaded quantity',
			dischargedQuantity: 'Discharged quantity',
			draught: 'Draught',
			draughtUnit: 'meter(s)',
			save: 'Save',
			editLogTitle: 'Edit log for {location}'
		},
		financialDetails: {
			financialDetails: 'Financial details',
			status: 'Status',
			client: 'Customer',
			transporter: 'Transporter',
			createInvoice: 'Create new invoice',
			revenue: 'Revenue',
			costs: 'Costs',
			sales: 'Yields',
			margin: 'Margin',
			saveFinancials: 'Save',
			products: 'Products',
			invoiceNumber: 'Invoice number',
			invoiceDate: 'Invoice date',
			external: 'External invoice',
			otherProducts: 'and 1 other product | and {count} other products',
			actions: {
				send: 'send',
				edit: 'edit',
				delete: 'delete'
			},
			invoices: {
				title: 'Invoices',
				sales: 'Yields',
				purchase: 'Costs'
			},
			invoiceLines: {
				title: 'Financial registrations',
				status: {
					initial: 'concept',
					filled: 'filled',
					checked: 'checked',
					invoiced: 'invoice created'
				},
				add: 'Add financial registration',
				edit: 'Edit financial registration',
				delete: 'Delete financial registration',
				edited_successfully: 'Financial registration has been updated successfully.',
				deleted_successfully: 'Financial registration has been deleted successfully.',
				invoiced_notification:
					'Financial registration has already been invoiced, the invoice will not be changed automatically.',
				error_while_updating: 'An error occurred while updating the financial registration. Try again later.',
				error_while_deleting: 'An error occurred while deleting the financial registration. Try again later.',
				save: 'save',
				cancel: 'cancel',
				create: 'bill now',
				disabled: 'Fill in loaded/discharged quantity',
				priceDependentOn: 'of {amount}',
				incomingInvoice: 'Register incoming invoice',
				relationOther: 'Not connected to relation',
				nya: 'N.y.a.'
			}
		},
		addFile: 'Add file',
		vesselPosition: {
			title: 'Report a position manually',
			reportPosition: 'Report position',
			searchLocation: 'Vessel location',
			mandatoryField: 'This is a mandatory field',
			recordDatetime: 'Record time',
			save: 'Save',
			reportSuccess: 'Position reported'
		},
		manifestDetails: {
			manifestDetails: 'Manifest / Loading list / Unloading list',
			equipmentNumber: 'Container number',
			statuses: 'Statuses',
			weight: 'Weight',
			measurements: 'Dimensions',
			status: {
				blocked: 'Blocked',
				customsDocument: 'Customs document',
				cargoOpening: 'Cargo opening',
				bookingApproved: 'Booking approved',
				removed: 'Removed from PortBase',
				notConfirmed: 'Not confirmed'
			},
			confirmCancel: {
				title: 'Confirm',
				body: 'Are you sure you want to cancel the handling in PortBase?',
				button: 'Confirm'
			},
			switchCompany: {
				title: 'Change shipping company',
				button: 'Save',
				company: 'Shipping company'
			}
		},
		gasOilUsages: {
			calculationWarningUnrealistic: 'Unrealistic consumption ({value} m3) for specified period.',
			created_succesfully: 'Your gas oil consumption is registered',
			gasOilUsages: 'Gas oil consumption',
			showWeekNumbers: 'Show week numbers',
			gasOil: 'Gasoil',
			edit: 'Change',
			add: 'Create',
			perWeek: 'Per week',
			perPeriod: 'Per period',
			weekNumber: 'Week number',
			gasOilUsageDeleted: 'Gas oil registration removed',
			noGasOilUsages: 'You have not yet registered gas oil consumption',
			gasOilLevelStart: 'Start bunker level in m3',
			gasOilLevelEnd: 'End bunker level in m3',
			gasOilBunkered: 'Gas oil bunkered in m3',
			usage: 'Consumption',
			usageInCubic: 'Consumption in m3',
			startDate: 'Start date',
			endDate: 'End date',
			permissionsToIncrementSpeed: 'Number of increased velocity permissions',
			calculationWarning: 'Your final level is higher than the initial level. Increase bunkering value.',
			bunkerReceipt: 'Bunker receipt',
			addBunkerReceipt: {
				uploadReceipt: 'Upload receipt',
				addBunkerReceipt: 'Add bunker receipt',
				add: 'Add',
				cancel: 'Cancel',
				pickFile: 'Choose File',
				makePhoto: 'Take photo'
			}
		},
		vesselLogs: {
			logBook: 'Log',
			vesselLogs: 'Ship log',
			vesselLogDeleted: 'Log entry deleted',
			terminal: 'Terminal',
			berth: 'Berth',
			bunker_station: 'Bunker station',
			lock: 'Lock',
			showVesselLogs: 'Show log',
			showGasOilUsage: 'Show gas oil consumption',
			noVesselLogs: 'You have no logged activities yet',
			created_succesfully: 'Your ship log has been saved!',
			vesselLog: 'Log item',
			mmsi: 'mmsi',
			sailed_during_action: 'Sailed during this action',
			arrival_time: 'Arrival day / time:',
			departure_time: 'Departure day / time:',
			arrival_location: 'Arrival location:',
			departure_location: 'Departure location:',
			add: 'Create',
			edit: 'Change',
			start_time_trans_shipment: 'Start time loading / unloading',
			end_time_trans_shipment: 'End time loading / unloading',
			loadedContainerQuantity: 'Quantity of containers loaded',
			dischargedContainerQuantity: 'Quantity of containers unloaded',
			rearrangedContainerQuantity: 'Quantity of containers moved',
			add_additional_info: 'I want to provide additional information',
			add_comment: 'I want to add a comment',
			comments: 'Remarks',
			add_comment_labels: 'Remark category',
			addVesselLog: 'Add',
			searchFacility: 'Search facility',
			unknownLocation: 'Location unknown',
			gasOilPricePerCubic: 'Price per m3',
			gasOilBunkeredQuantity: 'Bunker quantity in m3',
			selectVessel: 'Select a vessel'
		},
		requiredDocuments: {
			title: 'Required documents'
		}
	},
	profile: {
		'keep-up-to-date': 'Keep your data up to date!',
		user: 'User',
		company: 'Company',
		connections: 'Connections',
		userManagement: 'User management',
		vessel: 'Ship',
		edit: 'Save changes',
		edittedSuccessfully: 'Your changes have been processed.',
		'user-details': {
			firstName: 'Choose first name',
			lastName: 'Choose last name',
			password: 'Choose new password',
			email: 'Your email address',
			language: 'Language',
			passwordStrength: {
				weak: 'Weak',
				medium: 'Medium',
				strong: 'Strong'
			},
			passwordHints: {
				useNumbersAndSpecialCharacters: 'Use numbers and special characters',
				useNumbers: 'Use numbers',
				useSpecialCharacters: 'Use special characters'
			}
		},
		'company-details': {
			companyName: 'Company Name',
			reference: 'Reference',
			cocNumber: 'Chamber of Commerce number',
			vatNumber: 'VAT number',
			iban: 'IBAN number',
			streetName: 'Address',
			postalCode: 'Postal Code',
			place: 'Place',
			country: 'Country',
			website: 'Website',
			disclaimer: 'Disclaimer',
			phoneCountryCode: 'Country Code',
			phoneNumber: 'Telephone number',
			hasSecondaryPhone: 'I want to provide a second telephone number',
			hasTertiaryPhone: 'I want to provide a third telephone number',
			hasAlternativeInvoiceDetails: 'I have a different billing address',
			hasAlternativeMailDetails: 'I have a different mailing address',
			invoiceEmail: 'Billing address e-mail address',
			invoiceStreetName: 'Billing address street',
			invoicePostalCode: 'Billing address postal code',
			invoicePlace: 'Billing address place',
			invoiceCountry: 'Billing address country',
			mailStreetName: 'Postal address street',
			mailPostalCode: 'Postal address postal code',
			mailPlace: 'Postal address place',
			mailCountry: 'Postal address country'
		},
		'vessel-details': {
			euNumber: 'ENI number (Europe number)',
			insurance: 'Insurance',
			length: 'Length (in meters)',
			name: 'Name of the ship',
			tonnage: 'Tonnage (in tonnes)',
			phoneCountryCode: 'Country Code',
			phoneNumber: 'Telephone number'
		},
		'connection-details': {
			intro: 'You can manage your links with your financial system on this page.',
			exact: {
				title: 'Exact-Online',
				details: 'Connect your account so that invoices are created in Exact-Online.'
			},
			internal: {
				title: '4Shipping',
				details: 'Create your invoices in 4Shipping and manage these on the invoicing page.',
				success: 'The invoicing module is successfully activated.',
				saved: 'The configuration is successfully saved.'
			},
			status: 'Status',
			activated: 'Activated',
			connectedUntil: 'Connection active until',
			expiredOn: 'Connection expired on',
			notConnected: 'Not connected',
			notActivated: 'Not activated',
			connect: 'Connect',
			activate: 'Activate',
			forwardEmail: 'Forward address',
			clean_slate: {
				headline: 'Membership needed',
				request: 'Do you want to create and send invoices with 4Shipping?',
				contact_us: 'Contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40.'
			}
		},
		'connection-callback': {
			title: 'Connected succesfully',
			message: 'You may close this window and resume in your previous tab.'
		}
	},
	notifications: {
		selectNotifications: 'Select in which cases you would like to receive an email:',
		newMessages: 'When I receive a new unread message',
		newBidOnCargoOffer: 'When I receive a new offer on my offered cargo',
		cargoOfferedToVessel: 'When a cargo has been specifically offered to my barge',
		addedToGroup: 'When I am added to a group',
		relevantOffers: "In case of a newly published cargo offer that's relevant to me",
		charterGenerated: 'When a charter is created for me',
		charterAccepted: 'When a skipper has accepted my charter',
		charterRejected: 'When a skipper has declined my charter',
		cargoOfferedToBroker: 'When a shipper has offered a cargo',
		savedSuccesfully: 'Your changes have been processed'
	},
	charterPreferences: {
		editCharterPreferences: 'View and change your charter settings:',
		editLogo: 'I want to change my logo',
		editDisclaimer: 'I want to change my disclaimer',
		disclaimer: 'Disclaimer',
		newLogo: 'Add new logo'
	},
	attributes: {
		selectPreferences: 'Please set your preferences',
		selectDetails: 'Please provide additional information',
		groups: {
			hold: 'Hold(s)',
			general: 'General',
			certificate: 'Certificates',
			preferences: 'Preferences',
			ungrouped: 'Miscellaneous'
		}
	},
	groups: {
		title: 'Your groups',
		favouriteVessels: {
			title: 'Favorite barges',
			details:
				'Here you can create and manage different groups to which you can add ships so that you can offer/tender transports specifically to this group.'
		},
		clientGroups: {
			title: 'Client groups',
			details: 'Here you can manage groups of clients in order to use those groups in price management.',
			makeNewClientGroup: 'Create new client group',
			newClientGroupName: 'Name of the new client group',
			add: 'Add',
			edit: 'Change',
			clientGroupDeleted: 'Your client group has been deleted.',
			clientGroupSavedSuccessfully: 'Your new client group has been created.',
			client: 'client',
			clients: 'clients',
			addClientGroupMember: 'Add',
			noClientGroupMember: "You don't have any client in the current group yet. Search and add one.",
			inviteWithdrawn: 'The client has been removed from the group.',
			duplicateClientMessage: 'This client has already been added.'
		},
		favouriteBrokers: {
			title: 'Favorite brokers',
			details:
				'Here you can create and manage different groups to which you can add registered shippers so that you can offer/tender transports specifically to this group. Please note, here you can only search for shippers who have enabled this.'
		},
		fleets: {
			title: 'Own fleet',
			details:
				'Here you can invite registered vessels if they belong to your own permanent (rental) fleet. If the skipper accepts this request, you will get more rights to see information from these ships. This is necessary for, among other things, the container monitor.'
		},
		clients: {
			title: 'Customers',
			clean_slate: {
				headline: 'Membership needed',
				request: 'Do you want to manage clients and add terms to transports?',
				contact_us: 'Contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40.'
			},
			details:
				'Here you can manage your customers yourself with their data, so that you can, for example, make order confirmations.'
		},
		brokers: {
			clean_slate: {
				headline: 'Membership required',
				request: 'Would you like to manage charterers and schedule transports through them?',
				contact_us: 'Please contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40.'
			},
			title: 'Brokers',
			details:
				'Here you can manage your brokers/shipping companies so you can schedule a transport via this broker/shipping company.'
		},
		vessels: {
			title: 'Vessel list',
			details:
				"Here you can manage your (unregistered) vessels yourself (they are not visible to anyone, but only to you). For example, you can create charters and, for example, download an overview of the vessel's data."
		}
	},
	fleets: {
		fleets: 'Fleets',
		ships: 'barges',
		ship: 'barge',
		noFleets: "You don't have your own fleet yet. Click the button to create your first fleet.",
		noFleetMember: 'You are not yet part of a fleet.',
		introductionSkipper: 'In the list below you can see which fleet you are member of or have been a member of.',
		makeNewFleet: 'Create new fleet',
		newFleetName: 'Name of the new fleet',
		fleetSavedSuccessfully: 'Your new fleet has been created',
		fleetMembers: {
			addMember: 'New barge',
			noFleetMembers: "You don't have any barges in this fleet yet. Click the button to add a barge.",
			memberSavedSuccesfully: 'A new barge has been added to your fleet.',
			pricePerDay: 'Price per day',
			rentalDaysPerWeek: 'Days per week',
			dailyShippingTimeInHours: 'Sailing hours per day',
			validFrom: 'From',
			validUntil: 'To',
			dateUnknown: 'For an unlimited period',
			noDateSpecified: 'Dates unknown',
			memberEdittedSuccesfully: 'Change the start and / or end date.'
		},
		newInvite: 'New invitation',
		newInvitee: 'Invited',
		add: 'Add',
		inviteWithdrawn: 'Your invitation has been withdrawn.',
		inviteDeclined: 'You declined the invitation. You are not part of the fleet (anymore).',
		inviteAccepted: 'You have accepted the invitation.',
		fleetDeleted: 'Your fleet has been deleted',
		edit: 'Change'
	},
	preferences: {
		notifications: 'Notifications',
		generalPreferences: 'Preferences',
		charterInfo: 'Charter information',
		invoiceInfo: 'Invoices',
		shipDetails: 'Barge details',
		shipDocuments: 'Barge documents',
		savedSuccesfully: 'Your changes have been processed.',
		'keep-up-to-date': 'Keep your preferences up to date!',
		vesselAttributes: {
			attributelabels: 'must come here'
		},
		invoice: {
			title: 'Invoice settings',
			documentFooter: 'Invoice footer'
		}
	},
	customLocation: {
		submit: 'Add',
		harbour: 'Port',
		waterway: 'River / canal',
		companyName: 'Company Name',
		city: 'City',
		country: 'Country',
		geoRequired: 'Select a location on the map',
		harbourName: 'Port name',
		harbourNumber: 'Port number',
		waterwayName: 'River / canal',
		waterwayKmNumber: 'Mileage number',
		phoneNumber: 'Telephone number',
		add: 'Add',
		update: 'Update',
		addLocation: 'Add a terminal',
		updateLocation: 'Update terminal data',
		created_succesfully: 'Your custom location has been created!'
	},
	terminal: {
		add: 'Add',
		update: 'Update',
		harbour: 'Port',
		waterway: 'River / canal',
		companyName: 'Company Name',
		city: 'City',
		country: 'Country',
		geoRequired: 'Select a location on the map',
		harbourName: 'Port name',
		harbourNumber: 'Port number',
		waterwayName: 'River / canal',
		waterwayKmNumber: 'Mileage number',
		locationMap: 'Choose a location on the map',
		phoneNumber: 'Telephone number',
		email: 'Email',
		submit: 'Add',
		addLocation: 'Add a terminal yourself',
		created_succesfully: 'Your terminal has been created!',
		user_managed: 'The data of this terminal is managed by your company.',
		comments: 'Comments'
	},
	confirmAction: {
		delete: {
			actionTranslation: 'Delete',
			actionContexts: {
				registration: {
					description:
						'Are you sure you want to delete the selected financial registration? When the financial registration is invoiced, the invoice line will not be deleted.',
					buttonText: 'Delete'
				},
				groupRemove: {
					description: 'Are you sure you want to delete the selected group?',
					buttonText: 'Delete'
				},
				cargo: {
					description:
						'Are you sure you want to delete the selected cargo (and if applicable the corresponding transport)?',
					buttonText: 'Delete'
				},
				cargoProject: {
					description: 'Are you sure you want to delete the selected project?',
					buttonText: 'Delete'
				},
				bid: {
					description: 'Are you sure you want to delete the selected bid?',
					buttonText: 'Delete'
				},
				favouriteGroup: {
					description: 'Are you sure you want to delete the selected favorite group?',
					buttonText: 'Delete'
				},
				favouriteGroupMember: {
					description: 'Are you sure you want to remove the selected skipper from your favorite group?',
					buttonText: 'Delete'
				},
				fleetShipper: {
					description: 'Are you sure you want to delete the selected fleet?',
					buttonText: 'Delete'
				},
				fleetSkipper: {
					reject: {
						description: "Are you sure you don't want to join this fleet and decline the invitation?",
						buttonText: 'Decline invitation'
					},
					leave: {
						description: 'Are you sure you want to leave the selected fleet?',
						buttonText: 'Leave fleet'
					}
				},
				fleetMember: {
					description: 'Are you sure you want to remove the selected skipper from your fleet?',
					buttonText: 'Delete'
				},
				groupMember: {
					description: 'Are you sure you want to remove the selected group member from your group?',
					buttonText: 'Delete'
				},
				clientGroupMember: {
					description: 'Are you sure you want to remove the selected client from your group?',
					buttonText: 'Delete'
				},
				vesselLog: {
					description: 'Are you sure you want to delete the selected vessel log?',
					buttonText: 'Delete'
				},
				gasOilUsage: {
					description: 'Are you sure you want to delete the selected gas oil registration?',
					buttonText: 'Delete'
				},
				unregisteredVesselRemove: {
					description: 'Are you sure you want to delete the selected vessel?',
					buttonText: 'Delete'
				},
				clientRemove: {
					description: 'Are you sure you want to delete the selected customer?',
					buttonText: 'Delete'
				},
				brokerRemove: {
					description: 'Are you sure you want to delete the selected broker?',
					buttonText: 'Delete'
				},
				invoiceRemove: {
					description: 'Are you sure you want to delete the selected invoice?',
					buttonText: 'Delete'
				},
				invoiceCredit: {
					description: 'Are you sure you want to credit the selected invoice?',
					buttonText: 'Credit'
				},
				terminalRemove: {
					description: 'Are you sure you want to delete the terminal?',
					buttonText: 'Delete'
				},
				emptyVesselReport: {
					description: 'Are you sure you want to delete the empty vessel report?',
					buttonText: 'Delete'
				},
				document: {
					description: 'Are you sure you want to delete the document?',
					buttonText: 'Delete'
				}
			}
		},
		show: {
			actionTranslation: 'show details',
			actionContexts: {
				vessel: {
					shipDetails: 'Ship details',
					historicTrack: 'Historic track',
					description: 'Press X to close details',
					buttonText: 'Okay'
				}
			}
		},
		cancel: 'Cancel'
	},
	vessel: {
		listItem: {
			sub: 'ENI: {eni} (L:{length} x W:{width})'
		}
	},
	unregisteredVessel: {
		create: 'Create',
		save: 'Save',
		success: 'The vessel is registered.',
		success_saved: 'The vessel is saved.',
		listItem: {
			sub: 'ENI: {eni} (L:{length} x W:{width})'
		},
		search: 'Search by name of vessel or skipper...'
	},
	dashboard: {
		pleaseSelect: 'Choose a dashboard',
		insights: 'Insights',
		vesselLogs: 'Log',
		selectFilter: 'Select your filter',
		filterInsights: 'Filter insights',
		fleet: 'Fleet',
		clean_slate: {
			headline: 'Check out your insights!',
			request:
				'Do you also want to monitor the footprint, gas oil consumption, costs and performance of your inland shipping transport in real time?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		},
		inventoryManagement: 'Inventory management',
		portManagement: {
			portReportMessage: 'Manage emailed reports',
			portManagement: '',
			portInsights: 'Port insights',
			selectFilter: 'Select filter',
			selectAll: 'Select all',
			shipType_placeholder: 'CEMT-class',
			activityType_placeholder: 'Activity',
			goodsType_placeholder: 'Commodity type',
			port_placeholder: 'Port',
			vessels: 'Vessels',
			vesselDetails: {
				name: 'Name',
				speed: 'Speed',
				eni: 'ENI',
				length: 'Length',
				width: 'Width',
				typeGeneric: 'Ship type - Generic',
				typeDetailed: 'Ship type - Detailed'
			},
			filters: {
				loading: 'Loading',
				discharge: 'Discharge'
			},
			clean_slate: {
				headline: 'Check out your insights!',
				request:
					'Do you also want to monitor the footprint, lock performance, berth occupation, vessel visits and throughput volume of your portarea(s)?',
				contact_us: 'Contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40.'
			}
		},
		transport: {
			insights: 'Transport insights',
			from: 'From',
			until: 'Until',
			noData: 'No data available',
			clean_slate: {
				headline: 'Membership needed',
				request: 'Do you want to have insights in financial results with 4Shipping?',
				contact_us: 'Contact us for a free demo!',
				contact_details: 'info@4shipping.com or +31 85 40 189 40.'
			}
		}
	},
	insights: {
		searchOriginTerminal: 'Find departure point terminal/transfer company',
		searchDestinationTerminal: 'Search destination terminal/transfer company',
		portCo2Emissions: {
			portCo2Emissions: 'CO2 emissions',
			emissionsInTonnage: 'Tonnes of CO2 emissions',
			emissionSavingsInTonnage: 'Tonnes of CO2 saved',
			graph_vertical_axis_title: {
				emissionsInTonnage: 'CO2 emissions total (tonnes)',
				emissionSavingsInTonnage: 'CO2 saved (tonnes)'
			},
			graph_tooltip: {
				emissionsInTonnage: '{value} tonnes of CO2 emissions',
				emissionSavingsInTonnage: '{value} tonnes of CO2 saved'
			}
		},
		lockPerformances: {
			lockPerformances: 'Lock performance (wait time in hours)',
			averageDelayTimeInHours: 'Average waiting time',
			maximumDelayTimeInHours: 'Maximum waiting time',
			graph_vertical_axis_title: {
				averageDelayTimeInHours: 'Average waiting time (hours)',
				maximumDelayTimeInHours: 'Maximum waiting time (hours)'
			},
			graph_tooltip: {
				averageDelayTimeInHours: '{value} hours deviated',
				maximumDelayTimeInHours: '{value} hours deviated'
			}
		},
		berthOccupations: {
			berthOccupations: 'Berth occupation (in %)',
			mediumOccupationPercentages: 'Berth occupation average',
			highOccupationPercentages: 'Occupancy maximum',
			graph_vertical_axis_title: {
				mediumOccupationPercentages: '% of total time',
				highOccupationPercentages: '% of total time'
			},
			graph_tooltip: {
				mediumOccupationPercentages: '{value}% of total time',
				highOccupationPercentages: '{value}% of total time'
			}
		},
		transShipments: {
			transShipments: 'transhipment volume',
			bulkTonnage: 'Bulk tonnage',
			containerQuantity: 'Containers TEU',
			graph_vertical_axis_title: {
				bulkTonnage: 'Bulk tonnage (tonnes)',
				containerQuantity: 'Container (TEU)'
			},
			graph_tooltip: {
				bulkTonnage: '{value} tonnes of bulk',
				containerQuantity: '{value} containers'
			}
		},
		vesselVisitations: {
			vesselVisitations: 'Ship visits',
			loadingOrDischargeVisits: 'loading/unloading',
			overnightStayVisits: 'overnightStayVisits',
			graph_vertical_axis_title: {
				loadingOrDischargeVisits: 'loading Or Discharge Visits',
				overnightStayVisits: 'overnight stays'
			},
			graph_tooltip: {
				loadingOrDischargeVisits: '{value} visits for loading or unloading',
				overnightStayVisits: '{value} visits for overnight stays'
			}
		},
		week: 'Week',
		month: 'Month',
		year: 'Year',
		all: 'All',
		altogether: 'Altogether',
		searchTerminal: 'Search terminal',
		co2Emissions: {
			co2Emissions: 'CO2 emission',
			total: 'Total',
			perUnit: 'Per unit',
			graph_vertical_axis_title: {
				total: 'CO2 emissions total (tonnes)',
				perUnit: 'CO2 emissions per unit (tonnes)'
			},
			graph_tooltip: {
				total: '{value} tonnes of CO2 emissions',
				perUnit: '{value} tonnes of CO2 emissions'
			}
		},
		transportPrices: {
			transportPrices: 'Transport price',
			total: 'Total',
			perUnit: 'Per unit',
			perTon: 'Per tonne',
			graph_vertical_axis_title: {
				total: 'Transport price (x1000)',
				perUnit: 'Transport price per unit',
				perTon: 'Transport price per tonne'
			},
			graph_tooltip: {
				total: '{value} thousand euro',
				perUnit: '{value} euro',
				perTon: '{value} euro'
			}
		},
		voyageDeviations: {
			voyageDeviations: 'Sailing deviations',
			total: 'Total',
			perUnit: 'Per unit',
			graph_vertical_axis_title: 'Deviations in hours',
			graph_tooltip: {
				total: '{value} hours deviated',
				perUnit: '{value} hours deviated'
			}
		},
		terminalDeviations: {
			terminalDeviations: 'Deviations at terminals',
			arrivalTimeDeviations: 'Arrival',
			handlingTimes: 'Handlings',
			departureTimeDeviations: 'Departure',
			graph_vertical_axis_title: {
				arrivalTimeDeviations: 'Deviations arrival in hours',
				handlingTimes: 'Handling time in hours',
				departureTimeDeviations: 'Deviations from departure in hours'
			},
			graph_tooltip: {
				arrivalTimeDeviationsPositive: '{value} hours earlier than scheduled',
				arrivalTimeDeviationsNegative: '{value} hours later than scheduled',
				departureTimeDeviationsPositive: '{value} hour ahead of schedule',
				departureTimeDeviationsNegative: '{value} hour later than schedule',
				arrivalTimeDeviations: '{value} hours deviated from arrival time',
				handlingTimes: '{value} hour of handling time',
				departureTimeDeviations: '{value} hour deviated from departure time'
			}
		},
		gasOil: {
			gasOil: 'Gas oil consumption & costs',
			usageInCubicMeter: 'm3',
			usagePriceInEuro: '\u20ac',
			usagePricePerCubicMeter: '\u20ac/m3',
			graph_vertical_axis_title: {
				usageInCubicMeter: 'Consumption in m3',
				usagePriceInEuro: 'Costs in Euro',
				usagePricePerCubicMeter: 'Average price per m3'
			},
			graph_tooltip: {
				usageInCubicMeter: '{value} m3 gas oil consumption',
				usagePriceInEuro: '{value} euro gas oil costs',
				usagePricePerCubicMeter: '{value} euro average cost per m3'
			}
		},
		shippedCargoes: {
			shippedCargoes: 'Freight carried',
			shippedContainerUnits: 'Containers',
			shippedTeu: 'TEU',
			shippedTonnage: 'Tonnage',
			graph_vertical_axis_title: {
				shippedTonnage: 'Tonnage transported',
				shippedTeu: 'Transported TEU',
				shippedContainerUnits: 'Containers transported'
			},
			graph_tooltip: {
				combined: '{emptyValue} empty and {fullValue} full',
				shippedTonnage: '{value} tonnes of cargo transported',
				shippedTeu: '{value} TEU transported',
				shippedContainerUnits: '{value} containers transported'
			}
		}
	},
	transportInsights: {
		useNewTransportFinancials: 'New financial registration',
		financialRegistrations: 'Financial registrations',
		invoiced: 'Invoiced',
		headers: {
			client: 'Client',
			vessel: 'Ship'
		},
		groupBy: {
			clients: 'Clients',
			vessels: 'Vessels',
			dossiers: 'Dossiers'
		},
		filterDialog: {
			all: 'All',
			selectAll: 'Select all',
			clients: 'Choose a client',
			vessels: 'Choose a barge',
			colleagues: 'Choose a colleague',
			productType: 'Product category/certification',
			productTypeLabel: 'Product category/certification',
			productTypePlaceholder: 'Choose a product type',
			invoiceProduct: 'Product/service',
			cargoProject: 'Cargo Project',
			productCategory: 'Product type'
		},
		tableHeadings: {
			transportPrices: 'Financials',
			shippedCargoes: 'Shipped cargo'
		},
		transportPrices: {
			revenue: 'Revenue',
			margin: 'Margin',
			costs: 'Costs'
		},
		shippedCargoes: {
			tonnage: 'Tonne',
			cubicMeters: 'Cubic Meters'
		},
		dossierInsights: {
			headers: {
				dossierNumber: 'Dossier number',
				date: 'Date',
				client: 'Client',
				vessel: 'Vessel (skipper)',
				product: 'Product',
				quantity: 'Quantity',
				loadingPlace: 'Loading place',
				unloadingPlace: 'Unloading place',
				prognosis: 'Expected revenue',
				registered: 'Registered revenue',
				invoicedRevenue: 'Invoiced revenue',
				invoicedCosts: 'Invoiced costs',
				invoiced: 'Invoiced revenue',
				salesRevenuePrognosis: 'Expected revenue',
				salesRevenueRegistered: 'Registered revenue',
				salesRevenueInvoiced: 'Invoiced revenue'
			},
			sales: 'Yields',
			purchase: 'Costs',
			cubicMeters: 'm3',
			tons: 'tons',
			tooltip: {
				detailsButton: 'Fetch details'
			}
		}
	},
	unregisteredVessels: {
		title: 'Create or edit an unregistered vessel',
		create: 'Create an unregistered vessel',
		noUnregisteredVessels: 'You have not yet registered any vessels.',
		searchHint: 'Insert a minimum of 3 characters to search.',
		deleted: 'The vessel is deleted.',
		tabs: {
			company: 'Company',
			vessel: 'Vessel',
			details: 'Details',
			preferences: 'Preferences',
			files: 'Files'
		}
	},
	registeredVessels: {
		title: 'Edit a registered vessel'
	},
	vessels: {
		documents: {
			expiration: {
				title: 'Change expiration date',
				date: 'Expiration date',
				placeholder: 'No expiration date'
			},
			valid_until: 'Valid until',
			editExpiry: 'Edit expiry date'
		},
		addFile: 'Add file',
		templates: {
			measurementCertificate: 'Download measurement certificate template',
			bargingLetter: 'Download barging letter template'
		}
	},
	clients: {
		noData: 'You have not yet created any customers.',
		create: 'Add a customer',
		deleted: 'The customer is deleted.',
		search: 'Search by name or city...'
	},
	brokers: {
		noData: 'You have not yet created any brokers/shipping companies.',
		create: 'Add a broker',
		deleted: 'The broker is deleted.',
		search: 'Search by name or city...'
	},
	intermodal_transport: {
		title: 'Multi-modal transport',
		actions: {
			submit: 'Offer',
			'show-route-details': 'Show transport options',
			locations: 'Configure locations'
		},
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you also want to transport individual containers through multiple transport-options?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		},
		container: {
			title: 'Freight details',
			equipmentType: 'Equipment type',
			description: 'Freight description',
			requirements: 'Requirements',
			unCode: {
				label: 'UN Code',
				hint: 'UN code should consist of 4 digits'
			},
			grossWeight: 'Gross weight',
			emptyWeight: 'Empty weight',
			teuValue: 'TEU amount',
			additionalDetails: 'Additional details'
		},
		pickup: {
			title: 'Pickup location',
			fromDate: 'Pickup from',
			toDate: 'Pickup until'
		},
		drop: {
			title: 'Drop location',
			fromDate: 'Drop from',
			toDate: 'Drop until'
		},
		price: {
			title: 'Price',
			targetPrice: 'Target price',
			publishEndDate: 'Publish until',
			modality: {
				label: 'Modality',
				vessel: 'Barge',
				train: 'Train',
				truck: 'Truck'
			}
		}
	},
	clientCreator: {
		title: 'Create customer',
		title_edit: 'Edit customer',
		submit: 'Create',
		save: 'Save',
		created_succesfully: 'The customer is successfully added.',
		saved: 'The customer is successfully saved.'
	},
	brokerCreator: {
		title: 'Create broker',
		title_edit: 'Edit broker',
		submit: 'Create',
		save: 'Save',
		created_succesfully: 'The broker is sucsessfully added.',
		saved: 'The broker is successfully saved.'
	},
	units: {
		hours: 'hours',
		days: 'days',
		shifts: 'shifts',
		cubic: 'cubic meters',
		ton: 'tons'
	},
	filterDialog: {
		selectFilter: 'Search',
		commitFilter: 'Filter',
		user: 'User'
	},
	generatorDialog: {
		title: 'Attach documents and adjust invoices',
		cancel: 'Cancel',
		generate: 'Generate',
		invoice_number: 'Invoice number',
		invoice_date: 'Invoice date',
		combined_attachments: 'Merge files',
		separate_attachments: 'Separate files',
		selected_language: 'Invoice language',
		invoiceNumberInUsed: 'This invoice number has already been used.',
		fetchNewInvoiceNumber: 'This invoice number has already been used, click the plus icon to get new number.'
	},
	invoiceCreator: {
		companyType: {
			label: 'Company type',
			client: 'Client',
			broker: 'Broker',
			supplier: 'Supplier',
			unregistered_terminal: 'Unregistered terminal',
			registered_terminal: 'Registered terminal',
			unregistered_vessel: 'Unregistered vessel',
			external_unregistered_vessel: 'External unregistered vessel',
			registered_vessel: 'Registered vessel',
			unknown: 'Unknown',
			otherOptions: "Don't filter on relation",
			allCustomers: 'All company types',
			hint: 'Filter the financial registrations by linked relation.'
		},
		subject: {
			sales: 'sales invoice',
			purchase: 'purchase invoice',
			unknown: 'invoice'
		},
		sales: 'Sales',
		purchase: 'Purchase',
		title: 'Create {subject}',
		title_external: 'Create external {subject}',
		title_edit: 'Edit {subject}',
		title_edit_external: 'Edit external {subject}',
		sub_title: 'Fill out the invoice lines to create an invoice.',
		sub_title_external: 'Fill out the invoice lines to register an external invoice.',
		sub_title_combined_sales: 'Create a combined invoice for a same customer.',
		sub_title_combined_purchase:
			'Create a combined self-billing invoice for the costs. By default we assume this invoice is for the transporter.',
		sub_title_warning: 'Not all clients or skippers are the same. ',
		review: 'Review invoice',
		journal: 'Journal',
		creditor: 'Creditor',
		debtor: 'Debtor',
		account_placeholder: 'Select client or vessel',
		search: 'Fill in a search term',
		search_more_characters: 'Fill in a minimum of 3 characters',
		search_no_data: 'Your search term did return no results',
		search_busy: 'Searching...',
		reference: 'Your reference',
		reference_short: 'Reference',
		description: 'General comment',
		product_number: 'Product/service',
		product_description: 'Remark',
		clientOrderReference: 'Client order reference',
		cargo_comments: 'Remark',
		cargo_amount: 'Amount',
		cargo_price: 'Price',
		cargo_price_total: 'Total',
		dependent_on: 'Of',
		quantity_percentage: 'Percentage',
		term_of_payment: 'Term of payment',
		invoice_date: 'Invoice date',
		invoice_number: 'Invoice number',
		invoice_for: 'Invoice for',
		invoice_of: 'Invoice of',
		vat: 'VAT',
		days: 'days',
		status: 'Put status directly on:',
		close_dossier: "Set dossier status to 'closed'",
		depend_quantity_on_cargo_quantity: 'Connect quantity to cargo volume',
		depend_quantity_on_cargo_quantity_transshipment_direction: {
			loading: 'Loading',
			unloading: 'Unloading'
		},
		unsync_with_financial_registration_message: 'There is a difference with financial records!',
		sync_financial_registration: 'Update registration automatically',
		actions: {
			edit: 'Edit',
			send: 'Send',
			store: 'Save',
			cancel: 'Cancel',
			submit: 'Show preview',
			close: 'Close',
			ready: 'Ready',
			addAttachments: 'Save + add attachment'
		},
		attachments: {
			title: 'Add attachments',
			subtitle: 'Attachment(s)'
		}
	},
	emailDialog: {
		attachment: 'Attachment',
		charterBody:
			'Dear {firstName},{newline}' +
			'{newline}' +
			'A new transport is created for the shipment of {cargoType}.{newline}' +
			'The charter is added as an attachment. This charter is a confirmation of the agreements made, if something is not correct, please contact us.{newline}' +
			'{newline}' +
			'We hereby confirm the following transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Quantity: {quantity}{newline}' +
			'Ship name: {shipName} ({dimensions}){newline}' +
			'Skipper name: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'Loading location: {startTerminal}{newline}' +
			'Loading: {loadingTerm}{newline}' +
			'Unloading location: {endTerminal}{newline}' +
			'Unloading: {unloadingTerm}{newline}' +
			'{newline}' +
			'Best regards,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		charterSend: 'Send charter',
		charterSubject: 'Charter received. Take a look! ({dossierNumber})',
		charterTitle: 'Message to skipper',
		charterTitleBroker: 'Message to bevrachter',
		copyMe: 'Send me a copy',
		from: 'Sender name',
		invalid_email: '{email} is not a valid email address',
		invoiceBody:
			'Dear {firstName},{newline}' +
			'{newline}' +
			'{newline}' +
			'The invoice is added as an attachment.{newline}' +
			'{newline}' +
			'Best regards,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		invoiceSend: 'Send invoice',
		invoiceSubject: 'Invoice nr. {invoiceNumber} ({invoiceRecipient})',
		invoiceTitle: 'Message to customer',
		message: 'Email message',
		orderBody:
			'Dear client,{newline}{newline}' +
			'We hereby confirm the following transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Quantity: {quantity}{newline}' +
			'Ship name: {shipName} ({dimensions}){newline}' +
			'Skipper name: {skipperName}{newline}' +
			'Tel: {phoneNumber}{newline}' +
			'Loading location: {startTerminal}{newline}' +
			'Loading: {loadingTerm}{newline}' +
			'Unloading location: {endTerminal}{newline}' +
			'Unloading: {unloadingTerm}{newline}' +
			'Your reference: {clientOrderReference}{newline}' +
			'{newline}' +
			'Best regards,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		orderSend: 'Send order',
		orderSubject: 'Order confirmation ({dossierNumber})',
		orderTitle: 'Message to client',
		quotationBody:
			'Dear client,{newline}{newline}' +
			'We hereby send you the quotation for the following transport:{newline}' +
			'Product: {cargoType}{newline}' +
			'Quantity: {quantity}{newline}' +
			'Loading location: {startTerminal}{newline}' +
			'Loading: {loadingTerm}{newline}' +
			'Unloading location: {endTerminal}{newline}' +
			'Unloading: {unloadingTerm}{newline}' +
			'Your reference: {clientOrderReference}{newline}' +
			'{newline}' +
			'Best regards,{newline}' +
			'{senderFullName}{newline}' +
			'{newline}' +
			'{senderSignature}',
		quotationSend: 'Send quotation',
		quotationSubject: 'Offerte',
		quotationTitle: 'Message to client',
		receivers: 'Receiver(s)',
		replyTo: 'Reply to',
		send: 'Send',
		sendAndFinalise: 'Send and schedule',
		cancel: 'Cancel',
		subject: 'Subject',
		title: 'Send',
		title_charter: 'Send charter',
		title_order: 'Send confirmation of order',
		title_charter_order: 'Send charter & confirmation of order',
		title_quotation: 'Send quotation'
	},
	genericSelector: {
		search: 'Fill in a search term',
		search_more_characters: 'Fill in a minimum of 3 characters',
		search_no_data: 'Your search term did return no results',
		search_busy: 'Searching...'
	},
	customerSelector: {
		placeholder: 'Select a relation',
		label: 'Relation'
	},
	productSelector: {
		placeholder: 'Select een product/service',
		label: 'Product/service'
	},
	authorizationDialog: {
		authorize: 'Authorize',
		click: 'Please click',
		here: 'here',
		exact: {
			toAuthorize: 'to authorize with Exact-Online.'
		},
		labels: {
			notFound: 'No connection was found.',
			expired: 'Your connection has expired.'
		}
	},
	externalInvoiceDownload: {
		title: 'Download external invoice',
		attachments: {
			subtitle: 'Invoice attachment(s)',
			by: 'by',
			empty: 'No invoices added to external invoice or transport.'
		},
		actions: {
			close: 'Close'
		}
	},
	invoices: {
		deleted: 'The invoice is deleted.',
		credited: 'The invoice has been credited, you can edit or send it.'
	},
	documentGenerator: {
		loading: 'The data is being retrieved.',
		errorWhileLoading: 'An error occurred while loading the data. Try again later.',
		billOfLading: {
			receiverTextForSkipper: 'Customer details will be automatically added on the document.',
			loadingBillReceiptNumber: 'Loading bill number',
			holdDimensionsOpen: 'Hatches open',
			holdDimensionsClosed: 'Hatches closed',
			title_loading: 'Generate bill of lading for building materials',
			title_unloading: 'Generate bill of unloading for building materials',
			receiver: 'Receiver',
			shipper: 'Supplier',
			generatedAt: 'Issue date',
			issueLocation: 'Issue place',
			general: 'General',
			vessel: 'Means of transport',
			skipperName: 'Name of skipper',
			destination: 'Unloading location',
			extractionArea: 'Extraction area',
			delivery_method: {
				label: 'Delivery method',
				franco_on_board: 'Franco on board',
				franco_on_shore: 'Franco on shore',
				franco_at_work: 'Franco at work',
				franco_on_truck: 'Franco on truck',
				franco_underwater: 'Franco underwater'
			},
			cargo: 'Product(s)/freight determination',
			startTerminal: 'Loading location',
			extractor: 'Extractor',
			holdDimensions: 'Capacity 0-line',
			product: 'Product',
			quantity: 'Quantity',
			quantityUnits: {
				tonnes: 'tonnes',
				cubic: 'cubic meters',
				cubic_meters: 'm3'
			},
			desalination: 'Desalination',
			sandIsRinsed: 'Sand is rinsed',
			desalinationMethods: {
				submerged: 'Completely submerged',
				hoses: 'With hose(s)'
			},
			desalinationWaterUsed: 'Water used',
			measuredSaltContent: 'Measured salt content',
			measuredSaltContentFrontHopper: 'Measured salt content front hopper',
			measuredSaltContentRearHopper: 'Measured salt content rear hopper',
			dryPumpUsed: 'Dry pump used',
			dryPumpHours: 'Dry pump time',
			loadingPeriodFrom: 'Start time loading',
			loadingPeriodUntil: 'End time loading',
			unloadingPeriodFrom: 'Start time unloading',
			unloadingPeriodUntil: 'End time unloading',
			rinsePeriodFrom: 'Start time rinsing',
			rinsePeriodUntil: 'End time rinsing',
			certificates: 'Certificates/regulations',
			certificateType: 'Certificate',
			'certificate-bsb': 'BSB',
			'certificate-benor': 'Benor',
			'certificate-no_bsb_benor': 'Nothing',
			noBsbOrBenor: 'Nothing',
			certificateMarking: 'Company Marking',
			licenseHolder: 'License holder',
			deedNumber: 'Deed number',
			certificationIsCE: 'CE certified',
			certificationIsDOP: 'DOP',
			certificationCEMarking: 'CE marking',
			certificationDOPMarking: 'DOP marking',
			certificationBRLMarking: 'BRL marking',
			skipperDeclareCleanHold: 'Skipper declares a clean cargo hold',
			certificationBenorRevision: 'Rev.',
			certificationBenorCertificateNumber: 'Cert. nr.',
			certificationClaim: 'Certificate number',
			certificateHolder: 'Certificate holder',
			environmentalQuality: 'Environmental quality',
			applicabilityClaim: 'Applicability',
			remark: 'Remarks',
			submit: 'Generate',
			transportWillBeCreated: 'A transport will be created'
		},
		billOfLadingTransport: {
			title: 'Generate bill of lading for transport',
			carrier: 'Carrier',
			shipper: 'Shipper',
			generatedAt: 'Date of issue',
			issueLocation: 'Issue location',
			subcontractedCarrier: '(Actual)carrier',
			vessel: 'Vessel',
			companyName: 'Company',
			vesselName: 'Vessel name',
			euNumber: 'EU number',
			skipperName: 'Name of skipper',
			destination: 'Port of discharge',
			cargo: 'Goods',
			transport: 'Transport',
			startTerminal: 'Port of loading',
			product: 'Product',
			quantity: 'Quantity',
			quantityUnits: {
				tonnes: 'ton',
				cubic: 'm3'
			},
			declarationShipper: 'Declaration by the shipper',
			instructionsShipper: 'Instructions of the shipper',
			other: 'Other',
			remark: 'Remark',
			selectedLanguage: 'Document language',
			submit: 'Generate'
		},
		advising: {
			title: 'Generate transport document',
			comments: 'Comments',
			selectedLanguage: 'Document language'
		},
		lciReport: {
			title: 'Create LCI',
			lastTransportedCargos: 'Last transported cargos',
			skipper: 'Skipper of vessel {vesselName}',
			skipperName: 'Skipper name',
			client: 'Delivered & Instructing Party',
			locations: 'Locations',
			startLocation: 'Start location',
			endLocation: 'End location',
			submit: 'Generate'
		},
		hopperMeasurementsReport: {
			title: 'Generate hopper measurement report',
			hopper: 'Hopper',
			frontHopper: 'Front hopper',
			rearHopper: 'Rear hopper',
			frontHopperDocument: 'Front hopper barging letter',
			rearHopperDocument: 'Rear hopper barging letter',
			starboard: 'Starboard',
			port: 'Port',
			explanation: 'If the load exceeds the rim, enter a negative number for the number of centimeters.',
			wheelhouse: 'Wheelhouse',
			hopperAmount: {
				single: 'Single hopper',
				double: 'Two hoppers'
			},
			total: 'Total',
			measurementInCm: 'In cm',
			loading: 'The barging letter is being loaded',
			noBargingLetter: 'No barging letters are found for this vessel, add a barging letter first.',
			noBargingLetterAuthorization: 'You do not have permission to view the barging letter'
		}
	},
	vesselContactCard: {
		title: 'Contact card',
		tabs: {
			vessel: 'Vessel',
			company: 'Company',
			preferences: 'Preferences'
		},
		vessel: {
			name: 'Vessel name',
			euNumber: 'ENI number',
			euNumberSuffix: 'Europe number',
			dimensions: 'Dimensions',
			tonnage: 'Tonnage',
			draught: 'Draught',
			sustainability: 'Sustainability',
			hold: 'Hold',
			certificates: 'Certificates',
			insurance: 'Insurance',
			email: 'Email address',
			phoneNumber: 'Phone number',
			skipperName: 'Skipper'
		},
		company: {
			name: 'Company name',
			phone: 'Phone number',
			email: 'Email address',
			website: 'Website',
			address: 'Address (visit)',
			postalCode: 'Postal code',
			place: 'Place',
			coc: 'CoC number',
			vat: 'VAT nr',
			postalAddress: 'Address (mail)',
			invoiceAddress: 'Address (invoices)'
		},
		fileName: 'Contactcard-{vesselName}.pdf',
		actions: {
			close: 'Close',
			download: 'Download contact card'
		}
	},
	loading_bill: {
		title: 'Create a loading bill',
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you also want to create loading-bills for transporting building materials?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		},
		empty: {
			headline: 'Create your first loading bill.',
			description:
				'On this page you will see the generated loading bills. Click on the button to open the form and start creating a loading-bill..'
		},
		new: {
			title: 'New loading-bill'
		},
		success: 'The loading-bill is successfully created and sent to the skipper.',
		actions: {
			submit: 'Generate + send',
			new: 'New loading-bill'
		},
		header: {
			title: 'Loading-bills'
		}
	},
	invoicing: {
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you want to create and send invoices with 4Shipping?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		},
		no_connection: {
			headline: 'This module needs to be activated.',
			details: "Go to your profile and activate the financial module below the 'Connections' page.",
			button: 'Click here to go to your connections.'
		},
		toolbar: {
			add: {
				sales: 'Sales invoice',
				purchase: 'Purchase invoice'
			},
			toggle: {
				sales: 'Sales',
				purchase: 'Purchase'
			}
		},
		showSent: 'Show sent',
		headline: 'Invoicing',
		table: {
			filters: {
				dossier_number: 'Search by dossier number...',
				invoice_number: 'Search by invoice number...',
				client_order_reference: 'Search by client order reference....'
			},
			headers: {
				invoiceNumber: 'Invoice number',
				dossierNumber: 'Dossier number',
				vesselName: 'Vessel name',
				unloadingDate: 'Unloading date',
				product: 'Product',
				customer: 'Relation (nr)',
				reference: 'Relation ref.',
				price: 'Price',
				status: 'Status'
			},
			status: {
				open: 'Concept / registered',
				generated: 'Created / agreed for payment',
				sent: 'Sent',
				paid: 'Paid',
				closed: 'Closed'
			},
			actions: {
				edit: 'Edit invoice',
				remove: 'Remove invoice',
				generate: 'Generate PDF',
				download: 'Download invoice',
				mail: 'Send invoice',
				credit: 'Credit invoice'
			},
			notifications: {
				saveStatus: {
					success: 'The status has been successfully changed.',
					error: 'The status could not be changed, try again later.'
				}
			}
		},
		send: {
			title: 'Send invoice',
			forward: 'This invoice will also be sent to {email}.',
			last_sent: 'Last sent:',
			to: 'To:',
			noSentEmails: 'No email has been sent successfully.'
		},
		status: {
			open: 'Concept invoice',
			open_external: 'Invoice registered',
			generated: 'Invoice generated',
			generated_external: 'Agreed for payment',
			sent: 'Sent',
			paid: 'Paid',
			paid_external: 'Paid',
			closed: 'Closed'
		}
	},
	'terminal-management': {
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you want to manage your terminals and schedule terminal visits with 4Shipping?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		}
	},
	'capacity-radar-management': {
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you want to manage your capacity radar with 4Shipping?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		}
	},
	'depot-management': {
		clean_slate: {
			headline: 'Membership needed',
			request: 'Do you want to manage your depot with 4Shipping?',
			contact_us: 'Contact us for a free demo!',
			contact_details: 'info@4shipping.com or +31 85 40 189 40.'
		}
	},
	preparePurchaseOrder: {
		title: 'Prepare purchase order',
		companyType: {
			label: 'Relation',
			client: 'Client',
			broker: 'Broker',
			supplier: 'Supplier',
			unregistered_terminal: 'Unregistered terminal',
			registered_terminal: 'Registered terminal',
			unregistered_vessel: 'Unregistered vessel',
			registered_vessel: 'Registered vessel',
			unknown: 'Unknown',
			otherOptions: "Don't filter on relation",
			allCustomers: 'All company types',
			hint: 'Filter the transports by linked relation.'
		},
		toggle: {
			revenue: 'Revenue',
			cost: 'Cost'
		},
		description: 'Create an export of all the deliveries incl. financial lines from the selected transports.',
		selectedTransports: 'Selected transports',
		clientOrderReference: {
			label: 'PO number (optional)',
			hint: 'If you select the company relation, this will overwrite the purchase order number of the financial registrations from the selected transports.',
			warning: 'This financial registration already had a PO number.'
		},
		submit: {
			success: 'The export has been downloaded.',
			success_without_download: 'The PO-number has been successfully assigned.',
			error: 'Something went wrong during the export. Please try again later.'
		},
		actions: {
			close: 'Close',
			export: 'Download export',
			saveExport: 'Save + download export',
			onlySave: 'Only save PO-number'
		}
	},
	downloadDocuments: {
		description: 'Choose the file types to download for the selected transports.',
		actions: {
			close: 'Close',
			download: 'Download'
		}
	}
}
